import { IconButton, List, ListItem } from "@mui/material";
import { SearchCriteria } from "components/models/offer";
import TooltipComponent from "components/reusable/Tooltip/Tooltip";
import { useAppSelector } from "components/store/configureStore";
import {
  MdArrowCircleDown,
  MdArrowCircleUp,
  MdDelete,
  MdOpenInNew,
} from "react-icons/md";
import getAxiosParams from "components/views/utils/getParams";
import { useNavigate } from "react-router-dom";
import { VALUE_SUFFIX } from "utils/constants";
import { useTranslation } from "react-i18next";
import ProfileTableLoader from "components/views/profile/components/ProfileTableLoader";
import ProfileTable from "components/views/profile/components/ProfileTable";

const MAX_LOCATIONS_VISIBLE = 3;

interface SearchCriteriasTableProps {
  favoriteSearchCriterias: SearchCriteria[];
  onDelete: (id: SearchCriteria["id"]) => void;
  isLoading?: boolean;
}

const SearchCriteriasTable = ({
  favoriteSearchCriterias,
  onDelete,
  isLoading,
}: SearchCriteriasTableProps) => {
  const { t } = useTranslation();
  const { City, District } = useAppSelector((state) => state.utility.table);
  const navigate = useNavigate();

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    criteria: SearchCriteria
  ) => {
    e.preventDefault();
    navigate(`/search/${getAxiosParams(criteria)}`);
  };

  if (isLoading) return <ProfileTableLoader />;

  return (
    <ProfileTable
      columns={[
        { id: "locations", title: t("profile:search-criteria-locations") },
        {
          id: "price",
          title: `${t("profile:search-criteria-price")} (${
            VALUE_SUFFIX.price as string
          })`,
        },
        {
          id: "sortedBy",
          title: t("profile:search-criteria-sorted-by"),
          hidden: ["xs"],
        },
        {
          id: "sortOrder",
          title: t("profile:search-criteria-sort-order"),
          hidden: ["xs", "sm"],
        },
        { id: "actions", title: t("profile:search-criteria-actions") },
      ]}
      rows={favoriteSearchCriterias.map((criteria) => ({
        id: criteria.id as number,
        columns: {
          locations: (
            <List>
              {criteria.location
                ?.slice(0, MAX_LOCATIONS_VISIBLE)
                .map((location) => (
                  <ListItem disableGutters key={location}>
                    {
                      City.find((city) => city.id === location.split(",")[0])
                        ?.name
                    }{" "}
                    {
                      District.find(
                        (district) => district.id === location.split(",")[1]
                      )?.name
                    }
                  </ListItem>
                ))}
              {criteria.location &&
                criteria.location?.length > MAX_LOCATIONS_VISIBLE && (
                  <TooltipComponent
                    WrappedComponent={
                      <ListItem disableGutters className="more-locations">
                        {t("profile:search-criteria-more-locations", {
                          count:
                            criteria.location.length - MAX_LOCATIONS_VISIBLE,
                        })}
                      </ListItem>
                    }
                    message={
                      <List>
                        {criteria.location
                          .slice(MAX_LOCATIONS_VISIBLE)
                          .map((location) => (
                            <ListItem disableGutters key={location}>
                              {
                                City.find(
                                  (city) => city.id === location.split(",")[0]
                                )?.name
                              }{" "}
                              {
                                District.find(
                                  (district) =>
                                    district.id === location.split(",")[1]
                                )?.name
                              }
                            </ListItem>
                          ))}
                      </List>
                    }
                  />
                )}
            </List>
          ),
          price: (
            <>
              {criteria.min_price} - {criteria.max_price}
            </>
          ),
          sortedBy: criteria.order_by,
          sortOrder: criteria.order ? (
            <TooltipComponent
              WrappedComponent={
                criteria.order === "asc" ? (
                  <MdArrowCircleUp />
                ) : (
                  <MdArrowCircleDown />
                )
              }
              message={t(
                `profile:search-criteria-sort-order-${criteria.order}`
              )}
            />
          ) : (
            "-"
          ),
          actions: (
            <>
              <TooltipComponent
                WrappedComponent={
                  <IconButton
                    aria-label="edit"
                    onClick={(e) => handleClick(e, criteria)}
                  >
                    <MdOpenInNew size={24} />
                  </IconButton>
                }
                message={t("profile:search-criteria-show-results")}
              />
              <TooltipComponent
                WrappedComponent={
                  <IconButton
                    aria-label="edit"
                    onClick={() => onDelete(criteria.id)}
                  >
                    <MdDelete size={24} />
                  </IconButton>
                }
                message={t("profile:search-criteria-delete")}
              />
            </>
          ),
        },
      }))}
    />
  );
};

export default SearchCriteriasTable;
