import { IconButton } from "@mui/material";
import { FavoriteOffer } from "components/models/offer";
import TooltipComponent from "components/reusable/Tooltip/Tooltip";
import { MdDelete, MdOpenInNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProfileTableLoader from "components/views/profile/components/ProfileTableLoader";
import ProfileTable from "components/views/profile/components/ProfileTable";

interface FavoritesTableProps {
  favoriteOffers: FavoriteOffer[];
  onDelete: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: FavoriteOffer["id"]
  ) => void;
  isLoading?: boolean;
}

const FavoritesTable = ({
  favoriteOffers,
  onDelete,
  isLoading,
}: FavoritesTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    offer: FavoriteOffer
  ) => {
    e.preventDefault();
    navigate(`/offer/${offer.id}`);
  };

  if (isLoading) return <ProfileTableLoader />;

  return (
    <ProfileTable
      columns={[
        { id: "title", title: t("profile:favorite-title") },
        { id: "type", title: t("profile:favorite-type"), hidden: ["xs"] },
        { id: "city", title: t("profile:favorite-city") },
        {
          id: "district",
          title: t("profile:favorite-district"),
          hidden: ["xs"],
        },
        { id: "size", title: t("profile:favorite-size"), hidden: ["xs", "sm"] },
        { id: "actions", title: t("profile:favorite-actions") },
      ]}
      rows={favoriteOffers.map((offer) => ({
        id: offer.id,
        columns: {
          title: offer.title,
          type: offer.type?.name ?? "-",
          city: offer.city?.name ?? "-",
          district: offer.district?.name ?? "-",
          size: `${offer.size ?? "-"} m²`,
          actions: (
            <>
              <TooltipComponent
                WrappedComponent={
                  <IconButton
                    onClick={(e) => handleClick(e, offer)}
                    sx={{ borderRadius: "50%", mr: 1 }}
                  >
                    <MdOpenInNew size={24} />
                  </IconButton>
                }
                message={t("profile:favorite-go-to-offer")}
              />
              <TooltipComponent
                WrappedComponent={
                  <IconButton
                    onClick={(e) => onDelete(e, offer.id)}
                    sx={{ borderRadius: "50%" }}
                  >
                    <MdDelete size={24} />
                  </IconButton>
                }
                message={t("profile:search-criteria-delete")}
              />
            </>
          ),
        },
      }))}
    />
  );
};

export default FavoritesTable;
