import React from "react";
import { Grid, Typography } from "@mui/material";

interface Props {
  heading: string;
  text?: string;
  imgSrc: string;
  imgInRow?: boolean;
}

const CmsBanner = ({ heading, text, imgSrc, imgInRow }: Props) => {
  return (
    <>
      <Grid
        item
        container
        alignItems={"center"}
        gap={10}
        sx={{
          marginTop: "60px",
          width: "100%",
          height: !imgInRow ? "400px" : "unset",
          backgroundImage: !imgInRow ? imgSrc : "none",
          backgroundPosition: "center",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          justify: "center",
        }}
      >
        <Grid
          item
          xs={!imgInRow ? 12 : 10}
          sm={!imgInRow ? 12 : 5}
          sx={{
            width: "100%",
            padding: imgInRow ? "20px 0" : "100px 20%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            textAlign: !imgInRow ? "center" : "left",
          }}
        >
          <Typography variant="h1" sx={{ padding: "20px 0" }}>
            {heading}
          </Typography>
          {text && <Typography variant="subtitle1">{text}</Typography>}
        </Grid>
        {imgInRow && (
          <Grid item xs={12} sm={4} sx={{ textAlign: "right" }}>
            <img
              style={{ maxHeight: "300px", height: "auto" }}
              src={imgSrc}
              alt="background"
            ></img>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CmsBanner;
