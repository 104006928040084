import {
  Breakpoint,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
} from "@mui/material";
import React from "react";

type Column<T extends string> = {
  id: T;
  title?: string;
  sx?: TableCellProps["sx"];
  headSx?: TableCellProps["sx"];
  hidden?: Breakpoint[];
};

interface Row<T extends string, K extends Column<T>> {
  id: string | number;
  columns: Record<K["id"], React.ReactNode>;
  onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void;
  sx?: TableRowProps["sx"];
}

interface ProfileTableProps<T extends string, K extends Column<T>> {
  columns: K[];
  rows: Row<T, K>[];
}

const ProfileTable = <T extends string, K extends Column<T>>({
  columns,
  rows,
}: ProfileTableProps<T, K>) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow
            sx={{ backgroundColor: (theme) => theme.palette.grey[100] }}
          >
            {columns.map(({ id, title, headSx, hidden = [] }) => (
              <TableCell
                key={id}
                sx={{
                  "&:not(:first-child)": { textAlign: "center" },
                  display: (theme) =>
                    Object.fromEntries(
                      theme.breakpoints.keys.map((key) => [
                        key,
                        hidden.includes(key) ? "none" : "table-cell",
                      ])
                    ),
                  ...headSx,
                }}
              >
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              {...(row.onClick
                ? {
                    role: "button",
                    onClick: row.onClick,
                    sx: {
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: (theme) =>
                          theme.palette.secondary.light,
                      },
                      ...row.sx,
                    },
                  }
                : { sx: row.sx })}
            >
              {columns.map(({ id, sx, hidden = [] }) => (
                <TableCell
                  key={id}
                  sx={{
                    "&:not(:first-child)": { textAlign: "center" },
                    display: (theme) =>
                      Object.fromEntries(
                        theme.breakpoints.keys.map((key) => [
                          key,
                          hidden.includes(key) ? "none" : "table-cell",
                        ])
                      ),
                    ...sx,
                  }}
                >
                  {row.columns[id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProfileTable;
