import rent from "../../../../images/icons/buyhouse.png";
import "./rentguide.scss";
import { Link } from "react-router-dom";
import SmallContact from "../../utils/SmallContact";
import EstateSlider from "../../utils/EstateSlider";
import { useTranslation } from "react-i18next";
import SearchWithState from "components/views/utils/Searcher/SearchWithState";
import { useAppDispatch } from "components/store/configureStore";
import { useEffect } from "react";
import { searchOffersAsync } from "components/views/offers/catalogSlice";
import { Grid, Paper, Typography } from "@mui/material";
import CmsBanner from "components/reusable/cms/CmsBanner";

const RentGuide = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(searchOffersAsync());
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      mt={10}
    >
      <Grid item xs={12}>
        <CmsBanner
          heading={t("rent:find-ideal-house")}
          imgSrc={rent}
          text={t("rent:realn-is-specialist")}
          imgInRow={true}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        style={{ backgroundColor: "#f4f6f7" }}
      >
        <Grid item container xs={8} gap={10} sx={{ padding: "50px 0 80px 0" }}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant="h2">{t("rent:why-is-worth")}</Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ padding: { xs: "10px 0", sm: "0 10px 10px 0" } }}
            >
              <Paper
                sx={{
                  padding: "10px",
                  height: "100%",
                  boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
                }}
              >
                <Grid container gap={2}>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={12}
                    alignItems={"center"}
                    sx={{ justifyContent: "center" }}
                  >
                    <Grid item xs={6}>
                      <img
                        src={rent}
                        alt="rent"
                        style={{ width: "100%", maxWidth: "180px" }}
                      ></img>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{ textAlign: { xs: "center", sm: "left" } }}
                    >
                      <Typography variant="h3">
                        {t("rent:best-offers")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      {t("rent:extensive-database")}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ padding: { xs: "20px 0", sm: "0 0 10px 10px" } }}
            >
              <Paper
                sx={{
                  padding: "10px",
                  height: "100%",
                  boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
                }}
              >
                <Grid container gap={2}>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={12}
                    alignItems={"center"}
                    sx={{ justifyContent: "center" }}
                  >
                    <Grid item xs={6}>
                      <img
                        src={rent}
                        alt="rent"
                        style={{ width: "100%", maxWidth: "180px" }}
                      ></img>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{ textAlign: { xs: "center", sm: "left" } }}
                    >
                      <Typography variant="h3">
                        {t("rent:professional-advice")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      {t("rent:experienced-real-agents")}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ padding: { xs: "10px 0", sm: "30px 10px 0 0" } }}
            >
              <Paper
                sx={{
                  padding: "10px",
                  height: "100%",
                  boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={12}
                    sx={{ justifyContent: "center" }}
                    gap={2}
                  >
                    <Grid item xs={6}>
                      <img
                        src={rent}
                        alt="rent"
                        style={{ width: "100%", maxWidth: "180px" }}
                      ></img>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Typography variant="h3">
                        {t("rent:efficient-procedures")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        {t("rent:highest-standarts")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ padding: { xs: "20px 0", sm: "30px 10px 0 10px" } }}
            >
              <Paper
                sx={{
                  padding: "10px",
                  height: "100%",
                  boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={12}
                    sx={{ justifyContent: "center" }}
                    gap={2}
                  >
                    <Grid item xs={6}>
                      <img
                        src={rent}
                        alt="rent"
                        style={{ width: "100%", maxWidth: "180px" }}
                      ></img>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Typography variant="h3">
                        {t("rent:reliability-and-trust")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        {t("rent:our-company-reliability")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ padding: { xs: "10px 0", sm: "30px 0 0 10px" } }}
            >
              <Paper
                sx={{
                  padding: "10px",
                  height: "100%",
                  boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={12}
                    sx={{ justifyContent: "center" }}
                    gap={2}
                  >
                    <Grid item xs={6}>
                      <img
                        src={rent}
                        alt="rent"
                        style={{ width: "100%", maxWidth: "180px" }}
                      ></img>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Typography variant="h3">
                        {t("rent:individual-approach")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        {t("rent:we-are-flexible")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={10}
        mt={10}
        mb={10}
      >
        <Grid item xs={11} sm={4}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap={10}
          >
            <Grid item container gap={5}>
              <Grid item>
                <Typography variant="h5" component="p">
                  {t("rent:dont-waste-time")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {t("rent:find-ideal-house")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SearchWithState />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11} sm={4}>
          <img src={rent} alt="luxury apartment" style={{ width: "100%" }} />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        style={{ backgroundColor: "#f4f6f7", padding: "40px 30px" }}
      >
        <Grid item xs={12} mb={10} sx={{ textAlign: "center" }}>
          <Typography variant="h2">{t("rent:more-materials")}</Typography>
        </Grid>
        <Grid item container sm={8}>
          <Grid item container xs={12} sm={5} justifyItems="center">
            <Grid item container xs={6} sm={6}>
              <Grid container mb={3}>
                <Paper
                  sx={{
                    padding: "10px",
                    boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
                  }}
                >
                  <Link
                    to="/guides/tips-for-renters"
                    style={{ textDecoration: "none", color: "#4f4f4f" }}
                  >
                    <Grid item>
                      <img
                        src={rent}
                        alt="rent"
                        style={{ width: "100%" }}
                      ></img>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {t("rent:advices")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {t("rent:next")}...
                      </Typography>
                    </Grid>
                  </Link>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" component="p">
              {t("rent:regardless")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ width: "70%" }}>
        <EstateSlider />
      </Grid>
      <SmallContact />
    </Grid>
  );
};

export default RentGuide;
