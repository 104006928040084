import guidepoeple from "../../../../../images/guidepeople.webp";
import guidebg from "../../../../../images/guidebg.webp";
import bulb from "../../../../../images/icons/bulb.png";
import { useTranslation } from "react-i18next";
import CmsBanner from "components/reusable/cms/CmsBanner";
import CmsArticle from "components/reusable/cms/CmsArticle";
import { Grid, Typography } from "@mui/material";
import CmsSubArticle from "components/reusable/cms/CmsSubArticle";

const BuyInSteps = () => {
  const { t } = useTranslation();
  const backgroundImage = `url(${guidebg})`;
  return (
    <Grid container mb={10} gap={10}>
      <CmsBanner
        heading={t("resources:how-to-buy-house-7-steps")}
        imgSrc={backgroundImage}
        text={`${t("resources:understanding-all-steps")} ${t(
          "resources:from-finding-agent"
        )}`}
      />
      <CmsArticle heading={t("resources:determine-budget")}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          gap={4}
        >
          <Grid item>
            <Typography variant="subtitle1">
              {t("resources:before-even-start")}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
            >
              <Grid item xs={12} sm={6}>
                <img src={bulb} alt="bulb"></img>
              </Grid>
              <Grid item xs={12} sm={6}>
                <aside>
                  <Typography variant="h6" component="h3">
                    <strong>{t("resources:agent-tip")}</strong>
                  </Typography>
                  <Typography variant="subtitle1">
                    {t("resources:typically")}
                  </Typography>
                </aside>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container gap={3}>
            <Grid item>
              <Typography variant="h6" component="h3">
                <strong>{t("resources:save-payment")}</strong>
              </Typography>
              <Typography variant="subtitle1">
                {t("resources:part-of-your-mortgage")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" component="h3">
                <strong>{t("resources:dont-forget-costs")}</strong>
              </Typography>
              <Typography variant="subtitle1">
                {t("resources:total-costs-charged")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CmsArticle>
      <CmsArticle heading={t("resources:step-2")}>
        <Grid
          container
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          gap={3}
        >
          <Grid item>
            {" "}
            <Typography variant="subtitle1">
              {t("resources:sellers-more-willing")}
            </Typography>
          </Grid>
          <Grid item>
            <CmsSubArticle heading={t("resources:apply-for-approval")}>
              <Typography variant="subtitle1">
                {t("resources:chosen-bank-1")}
              </Typography>
              <Typography variant="subtitle1">
                {t("resources:chosen-bank-2")}
              </Typography>
              <Typography variant="subtitle1">
                {t("resources:chosen-bank-3")}
              </Typography>
            </CmsSubArticle>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
            >
              <Grid item xs={12} sm={6}>
                <img src={bulb} alt="bulb"></img>
              </Grid>
              <Grid item xs={12} sm={6}>
                <aside>
                  <Typography variant="h6" component="h3">
                    <strong>{t("resources:agent-tip")}</strong>
                  </Typography>
                  <Typography variant="subtitle1">
                    {t("resources:remember-bank-consent")}
                  </Typography>
                </aside>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CmsArticle>
      <CmsArticle heading={t("resources:step-3")}>
        <Grid
          container
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          gap={3}
        >
          <Grid item>
            <Typography variant="subtitle1">
              {t("resources:agents-are-proffesional")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              <strong>{t("resources:no-commission-from-buyer")}</strong>
            </Typography>
          </Grid>
          <Grid item>
            {" "}
            <img
              src={guidepoeple}
              alt="people with agent"
              style={{ maxWidth: "100%" }}
            ></img>
          </Grid>
          <Grid item>
            <CmsSubArticle heading={t("resources:avoid-two-offices")}>
              <Grid item>
                <Typography variant="subtitle1">
                  {t("resources:avoid-two-offices-example-1")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {t("resources:avoid-two-offices-example-2")}
                </Typography>
              </Grid>
            </CmsSubArticle>
          </Grid>
        </Grid>
      </CmsArticle>
      <CmsArticle heading={t("resources:step-4")}>
        <Grid
          container
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          gap={3}
        >
          <Grid item>
            <Typography variant="subtitle1">
              {t("resources:approved-credit")}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
            >
              <Grid item xs={12} sm={6}>
                <img src={bulb} alt="bulb"></img>
              </Grid>
              <Grid item xs={12} sm={6}>
                <aside>
                  <Typography variant="h6" component="h3">
                    <strong>{t("resources:agent-tip")}</strong>
                  </Typography>
                  <Typography variant="subtitle1">
                    {t("resources:remember-like-property")}
                  </Typography>
                </aside>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {t("resources:during-presentation")}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
            >
              <Grid item xs={12} sm={6}>
                <img src={bulb} alt="bulb"></img>
              </Grid>
              <Grid item xs={12} sm={6}>
                <aside>
                  <Typography variant="h6" component="h3">
                    <strong>{t("resources:agent-tip")}</strong>
                  </Typography>
                  <Typography variant="subtitle1">
                    {t("resources:bring-someone-honest")}
                  </Typography>
                </aside>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CmsArticle>
      <CmsArticle heading={t("resources:step-5")}>
        <Grid
          container
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          gap={3}
        >
          <Grid item>
            <Typography variant="subtitle1">
              {t("resources:entrust-agent-1")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {t("resources:entrust-agent-2")}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
            >
              <Grid item xs={12} sm={6}>
                <img src={bulb} alt="bulb"></img>
              </Grid>
              <Grid item xs={12} sm={6}>
                <aside>
                  <Typography variant="h6" component="h3">
                    <strong>{t("resources:agent-tip")}</strong>
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong> {t("resources:prepare-to-negotiate")}</strong>{" "}
                    {t("resources:prepare-to-negotiate-text")}
                  </Typography>
                </aside>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CmsArticle>
      <CmsArticle heading={t("resources:step-6")}>
        <Grid
          container
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          gap={3}
        >
          <Grid item>
            <Typography variant="subtitle1">
              {t("resources:most-important-step-1")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {t("resources:most-important-step-2")}
            </Typography>
          </Grid>
        </Grid>
      </CmsArticle>
      <CmsArticle heading={t("resources:step-7")}>
        <Grid
          container
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          gap={3}
        >
          <Grid item>
            <Typography variant="subtitle1">
              {t("resources:final-step-reached-1")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {t("resources:final-step-reached-2")}
            </Typography>
          </Grid>
        </Grid>
      </CmsArticle>
    </Grid>
  );
};

export default BuyInSteps;
