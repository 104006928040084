import housebg from "../../../../images/icons/buyhouse.png";
import EstateSlider from "../../utils/EstateSlider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "components/store/configureStore";
import { searchOffersAsync } from "components/views/offers/catalogSlice";
import { useEffect, useState } from "react";
import LocationSelect from "components/reusable/LocationSelect";
import getAxiosParams from "components/views/utils/getParams";
import { Button, Grid, Typography, TextField } from "@mui/material";
import CmsBanner from "components/reusable/cms/CmsBanner";

const HouseNearby = ({ type }: { type: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [location, setLocation] = useState([""]);
  const [min_price, setMin_price] = useState("");
  const [max_price, setMax_price] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(searchOffersAsync(`buildType=${type}`));
  }, [type]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const params = {
      location,
      min_price,
      max_price,
    };
    navigate(`/search/${getAxiosParams(params)}`);
  };

  return (
    <Grid sx={{ margin: "100px 15% 0 15%" }}>
      <CmsBanner
        heading={t(`buy:find-${type}`)}
        text={t(`buy:check-${type}`)}
        imgSrc={housebg}
        imgInRow={true}
      />
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end"
          gap={10}
        >
          <Grid item xs={3}>
            <Typography variant="caption">{t("buy:location")}</Typography>
            <LocationSelect
              value={location}
              onChange={setLocation}
              multiple
              size="small"
              limitTags={1}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption">{t("buy:price-range")}</Typography>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction={"row"}
            >
              <Grid item xs={6} justifyContent="flex-end">
                <TextField
                  size={"small"}
                  type="number"
                  value={min_price}
                  onChange={(e) => setMin_price(e.target.value)}
                  placeholder={t("buy:min-price")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size={"small"}
                  type="number"
                  value={max_price}
                  onChange={(e) => setMax_price(e.target.value)}
                  placeholder={t("buy:max-price")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Button type="submit" sx={{ py: 5, px: 4, width: "200px" }}>
              {t("buy:search")}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid container direction="row" xs={12}>
        <Typography variant="h1" mt={10} mb={5}>
          {t(`buy:gdansk-${type}s`)}
        </Typography>
        <EstateSlider city="gdansk" type={type} showAditional />
      </Grid>
      <Grid container>
        <Typography variant="h1" mt={10} mb={5}>
          {t(`buy:gdynia-${type}s`)}
        </Typography>
        <EstateSlider city="gdynia" type={type} showAditional />
      </Grid>
      <Grid container>
        <Typography variant="h1" mt={10} mb={5}>
          {t(`buy:sopot-${type}s`)}
        </Typography>
        <EstateSlider city="sopot" type={type} showAditional />
      </Grid>
    </Grid>
  );
};

export default HouseNearby;
