import { useTranslation } from "react-i18next";
import CmsBanner from "components/reusable/cms/CmsBanner";
import CmsArticle from "components/reusable/cms/CmsArticle";
import CmsSubArticle from "components/reusable/cms/CmsSubArticle";
import { Grid, Typography } from "@mui/material";

const Possibilities = () => {
  const { t } = useTranslation();
  const backgroundImage = `url(${"https://cdn.pixabay.com/photo/2018/04/24/14/07/purchase-3347053_1280.jpg"})`;

  return (
    <Grid container gap={10} paddingBottom={20}>
      <CmsBanner
        heading={t("resources:possibilities")}
        imgSrc={backgroundImage}
      />
      <CmsArticle heading={t("resources:possibilities-in-realn")}>
        <Grid item>
          <Typography variant="subtitle1">
            {t("resources:we-belive")}
          </Typography>
        </Grid>
        <Grid item>
          <CmsSubArticle heading={t("resources:agent-career")}>
            <Typography variant="subtitle1">
              <strong>{t("resources:support")}</strong>{" "}
              {t("resources:support-text")}
            </Typography>
            <Typography variant="subtitle1">
              <strong>{t("resources:flexibility")}</strong>{" "}
              {t("resources:flexibility-text")}
            </Typography>
            <Typography variant="subtitle1">
              <strong>{t("resources:possible-income")}</strong>{" "}
              {t("resources:possible-income-text")}
            </Typography>
            <Typography variant="subtitle1">
              <strong>{t("resources:connections")}</strong>{" "}
              {t("resources:connections-text")}
            </Typography>
            <Typography variant="subtitle1">
              <strong>{t("resources:tech-support")}</strong>{" "}
              {t("resources:tech-support-text")}
            </Typography>
          </CmsSubArticle>
        </Grid>

        <Grid item>
          <CmsSubArticle heading={t("resources:realn-career")}>
            <Typography variant="subtitle1">
              <strong>{t("resources:innovative-projects")}</strong>{" "}
              {t("resources:innovative-projects-text")}
            </Typography>
            <Typography variant="subtitle1">
              <strong>{t("resources:experts")}</strong>{" "}
              {t("resources:experts-text")}
            </Typography>
            <Typography variant="subtitle1">
              <strong>{t("resources:company-development")}</strong>{" "}
              {t("resources:company-development-text")}
            </Typography>
            <Typography variant="subtitle1">
              <strong>{t("resources:future-creation")}</strong>{" "}
              {t("resources:future-creation-text")}
            </Typography>

            <Typography variant="subtitle1">
              {t("resources:not-important")}
            </Typography>
          </CmsSubArticle>
        </Grid>
      </CmsArticle>
    </Grid>
  );
};

export default Possibilities;
