import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import CmsBanner from "components/reusable/cms/CmsBanner";
import CmsArticle from "components/reusable/cms/CmsArticle";
import CmsList from "components/reusable/cms/CmsList";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const backgroundImage = `url(${"https://cdn.pixabay.com/photo/2018/04/24/14/07/purchase-3347053_1280.jpg"})`;

  return (
    <Grid container gap={10} paddingBottom={20}>
      <CmsBanner
        heading={t("privacy:privacy-policy")}
        imgSrc={backgroundImage}
      />
      <CmsArticle>
        <Typography variant="subtitle1">
          {t("privacy:following-privacy-policy")}{" "}
          <strong>{t("privacy:rules-for-saving")}</strong>
        </Typography>
        <Typography variant="subtitle1">
          {t("privacy:integral-part")}{" "}
          <a href="https://realn.pl/regulamin">{t("privacy:regulations")}</a>
          {t("privacy:regulatios-part-2")}
        </Typography>
      </CmsArticle>
      <CmsArticle heading={t("privacy:chapter-1")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:service")}</strong>{" "}
              {t("privacy:service-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:external-service")}</strong>{" "}
              {t("privacy:external-service-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:service-admin")}</strong>{" "}
              {t("privacy:service-admin-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:user")}</strong> {t("privacy:user-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:device")}</strong> {t("privacy:device-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:cookies")}</strong>{" "}
              {t("privacy:cookies-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:rodo")}</strong> {t("privacy:rodo-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:personal-data")}</strong>{" "}
              {t("privacy:personal-data-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:processing")}</strong>{" "}
              {t("privacy:processing-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:processing-restrictions")}</strong>{" "}
              {t("privacy:processing-restrictions-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:profiling")}</strong>{" "}
              {t("privacy:profiling-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:agreement")}</strong>{" "}
              {t("privacy:agreement-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:breach-of-personal-data")}</strong>{" "}
              {t("privacy:breach-of-personal-data-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:pseudonymization")}</strong>{" "}
              {t("privacy:pseudonymization-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:annoymization")}</strong>{" "}
              {t("privacy:annoymization-text")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("privacy:data-protection")}>
        <Typography variant="subtitle1">{t("privacy:37-rodo")}</Typography>
        <Typography variant="subtitle1">{t("privacy:37-rodo-text")}</Typography>
      </CmsArticle>
      <CmsArticle heading={t("privacy:types-of-cookies")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:external-cookies")}</strong>{" "}
              {t("privacy:external-cookies-text-1")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:internal-cookies")}</strong>{" "}
              {t("privacy:internal-cookies-text-1")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:session-cookies")}</strong>{" "}
              {t("privacy:session-cookies-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:persistent-cookies")}</strong>{" "}
              {t("privacy:persistent-cookies-text")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("privacy:security-of-data-storage")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:mechanism-for-storing-cookies")}</strong>{" "}
              {t("privacy:mechanism-for-storing-cookies-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:internal-cookies")}</strong>{" "}
              {t("privacy:internal-cookies-text-2")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:external-cookies")}</strong>{" "}
              {t("privacy:external-cookies-text-2")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:cookie-control")}</strong>
              <ul>
                <li>
                  <Typography variant="subtitle1">
                    {t("privacy:user-may-at-any-time")}
                  </Typography>
                </li>
                <li>
                  <Typography variant="subtitle1">
                    {t("privacy:info-cookie-part-1")}{" "}
                    <a
                      rel="external"
                      href="https://nety.pl/jak-wylaczyc-pliki-cookie/"
                    >
                      {t("privacy:info-cookie-part-2")}
                    </a>{" "}
                    {t("privacy:info-cookie-part-3")}
                    <ul>
                      <li>
                        <a
                          rel="nofollow external"
                          href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&#038;hl=pl"
                        >
                          {t("privacy:cookies-management")}{" "}
                          <strong>Chrome</strong>
                        </a>
                      </li>
                      <li>
                        <a
                          rel="nofollow external"
                          href="https://help.opera.com/pl/latest/web-preferences/"
                        >
                          {t("privacy:cookies-management")}{" "}
                          <strong>Opera</strong>
                        </a>
                      </li>
                      <li>
                        <a
                          rel="nofollow external"
                          href="https://support.mozilla.org/pl/kb/blokowanie-ciasteczek"
                        >
                          {t("privacy:cookies-management")}{" "}
                          <strong>FireFox</strong>
                        </a>
                      </li>
                      <li>
                        <a
                          rel="nofollow external"
                          href="https://support.microsoft.com/pl-pl/help/4027947/microsoft-edge-delete-cookies"
                        >
                          {t("privacy:cookies-management")}{" "}
                          <strong>Edge</strong>
                        </a>
                      </li>
                      <li>
                        <a
                          rel="nofollow external"
                          href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac"
                        >
                          {t("privacy:cookies-management")}{" "}
                          <strong>Safari</strong>
                        </a>
                      </li>
                      <li>
                        <a
                          rel="nofollow external"
                          href="https://windows.microsoft.com/pl-pl/internet-explorer/delete-manage-cookies#ie=ie-11"
                        >
                          {t("privacy:cookies-management")}{" "}
                          <strong>Internet Explorer 11</strong>
                        </a>
                      </li>
                    </ul>
                  </Typography>
                </li>
                <li>
                  <Typography variant="subtitle1">
                    {t("privacy:user-can-delete")}
                  </Typography>
                </li>
              </ul>
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:user-threats")} </strong>{" "}
              {t("privacy:user-threats-text")}{" "}
              <a rel="external" href="https://nety.pl/cyberbezpieczenstwo/">
                {t("privacy:cyber-security")}
              </a>
              .
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:personal-data-storage")}</strong>{" "}
              {t("privacy:personal-data-storage-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:password-storage")}</strong>{" "}
              {t("privacy:password-storage-text")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("privacy:purposes-cookies-are-used")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:improving-access")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:personalization")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:loggin")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:marketing")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:advertising")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:affiliate")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:stats")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:multimedia")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:community")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("privacy:personal-data-goals")}>
        <Typography variant="subtitle1">{t("privacy:voluntarily")}</Typography>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:electronic-services")}
              <ul>
                <li>
                  <Typography variant="subtitle1">
                    {t("privacy:registration-service")}
                  </Typography>
                </li>
                <li>
                  <Typography variant="subtitle1">
                    {t("privacy:newsletter")}
                  </Typography>
                </li>
                <li>
                  <Typography variant="subtitle1">
                    {t("privacy:comments")}
                  </Typography>
                </li>
                <li>
                  <Typography variant="subtitle1">
                    {t("privacy:sharing")}
                  </Typography>
                </li>
              </ul>
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:admin-communication")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:ensuring-admin")}
            </Typography>
          </li>
        </CmsList>
        <Typography variant="subtitle1">{t("privacy:user-data")}</Typography>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:keep-statistics")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:remarketing")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:serving")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:affiliate-service")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:legally-justified")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("privacy:chapter-7")}>
        <Typography variant="subtitle1">
          {t("privacy:admin-uses-js")}
        </Typography>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:multimedia-service")}</strong>
              <CmsList>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://www.youtube.com/t/terms"
                  >
                    YouTube
                  </a>
                </li>
                <li>
                  <a rel="nofollow external" href="https://vimeo.com/terms">
                    Vimeo
                  </a>
                </li>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://livestream.com/legal/terms"
                  >
                    Livestream
                  </a>
                </li>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://soundcloud.com/pages/privacy"
                  >
                    SoundCloud
                  </a>
                </li>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://www.spotify.com/pl/legal/privacy-policy/"
                  >
                    Spotify
                  </a>
                </li>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://www.podchaser.com/privacy.html"
                  >
                    Podchaser
                  </a>
                </li>
              </CmsList>
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:social-services")}</strong>
              <br />
              <ul>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://www.facebook.com/legal/terms"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://policies.google.com/privacy?hl=pl"
                  >
                    Google+
                  </a>
                </li>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://www.linkedin.com/legal/user-agreement"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:sharing-services")}</strong>
              <ul>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://policy.pinterest.com/pl/privacy-policy"
                  >
                    Pinterest
                  </a>
                </li>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://slack.com/intl/en-pl/privacy-policy?eu_nc=1"
                  >
                    Slack
                  </a>
                </li>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://www.whatsapp.com/privacy/?lang=pl"
                  >
                    WhatsApp
                  </a>
                </li>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://help.instagram.com/519522125107875"
                  >
                    Instagram
                  </a>
                </li>
              </ul>
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:newsletter-only")}</strong>
              <ul>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://mailchimp.com/legal/privacy/"
                  >
                    MailChimp
                  </a>
                </li>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://legal.hubspot.com/privacy-policy"
                  >
                    HubSpot
                  </a>
                </li>
              </ul>
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:ad-services")}</strong>
              <ul>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://policies.google.com/privacy?hl=pl"
                  >
                    Google Adsense
                  </a>
                </li>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://mylead.global/pl/polityka-prywatnosci"
                  >
                    MyLead
                  </a>
                </li>
              </ul>
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:keep-statistics")}</strong>
              <ul>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://policies.google.com/privacy?hl=pl"
                  >
                    Google Analytics
                  </a>
                </li>
              </ul>
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:other-services")}</strong>
              <ul>
                <li>
                  <a
                    rel="nofollow external"
                    href="https://www.google.com/intl/en_be/help/terms_maps/"
                  >
                    {t("privacy:google-maps")}
                  </a>
                </li>
              </ul>
            </Typography>
          </li>
        </CmsList>
        <Typography variant="subtitle1">
          {t("privacy:third-parties")}
        </Typography>
      </CmsArticle>
      <CmsArticle heading={t("privacy:chapter-8")}>
        <Typography variant="subtitle1">
          {t("privacy:collects-data")}
        </Typography>
        <CmsList heading={t("privacy:anonymous-data")}>
          <li>
            <Typography variant="subtitle1">{t("privacy:ip")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:browser-type")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:screen-resolution")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:location")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:opened-website")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:time-spent")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:system")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:last-page")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:current-page")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:language")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:connection-speed")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:internet-provider")}
            </Typography>
          </li>
        </CmsList>
        <CmsList heading={t("privacy:data-collected-during-registration")}>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:name-surname")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:login")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:email")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:profile-picture")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:address")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:sex")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:birth-age")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:social-media-address")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:phone")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:ip-address")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:nip")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:other-regular-data")}
            </Typography>
          </li>
        </CmsList>

        <CmsList heading={t("privacy:data-collected-when-subscribing")}>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:name-surname")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:email")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:sex")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:birth-age")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:ip-address")}
            </Typography>
          </li>
        </CmsList>
        <CmsList heading={t("privacy:data-collected-when-commenting")}>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:name-surname")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:email")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:www")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:ip-address")}
            </Typography>
          </li>
        </CmsList>
        <Typography variant="subtitle1">{t("privacy:part-data")}</Typography>
      </CmsArticle>
      <CmsArticle heading={t("privacy:chapter-9")}>
        <Typography variant="subtitle1">
          {t("privacy:only-receiver")}
        </Typography>
        <Typography variant="subtitle1">{t("privacy:data-access")}</Typography>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:hosting-companies")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:companies-newsletter")}
            </Typography>
          </li>
        </CmsList>
        <Typography variant="subtitle1">
          <strong>{t("privacy:entrusting-personal-data")}</strong>
        </Typography>
        <Typography variant="subtitle1">
          {t("privacy:admin-provide")}{" "}
          <strong>
            <a
              rel="nofollow external"
              href="https://mailchimp.com/legal/privacy/"
            >
              MailChimp
            </a>
            ,{" "}
            <a
              rel="nofollow external"
              href="https://legal.hubspot.com/privacy-policy"
            >
              HubSpot
            </a>
            ,{" "}
          </strong>
          . {t("privacy:admin-provide-text")}
        </Typography>
        <Typography variant="subtitle1">{t("privacy:partner-info")}</Typography>
        <br />
        <Typography variant="subtitle1">
          <strong>{t("privacy:processing-hosting-vps")}</strong>
        </Typography>
        <Typography variant="subtitle1">
          {t("privacy:external-partner-help")}{" "}
          <strong>
            <a
              rel="nofollow external"
              href="https://www.ovh.pl/ochrona-danych-osobowych/"
            >
              OVH sp. z o.o.
            </a>
          </strong>
          . {t("privacy:external-partner-help-text")}
        </Typography>
        <br />
      </CmsArticle>
      <CmsArticle heading={t("privacy:chapter-10")}>
        <CmsList heading={t("privacy:chapter-10-desc")}>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:chapter-10-paragraph-1")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:chapter-10-paragraph-2")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:chapter-10-paragraph-3")}
            </Typography>
          </li>
        </CmsList>
        <CmsList heading={t("privacy:anonim-data")}>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:anonymous-data-paragraph-1")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:anonymous-data-paragraph-2")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:anonymous-data-paragraph-3")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("privacy:chapter-11")}>
        <Typography variant="subtitle1">
          {t("privacy:chapter-11-desc")}
        </Typography>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:regulation")}
              <ul>
                <li>
                  art. 6 ust. 1 lit. a<br />
                  <small>{t("privacy:art-6-a")}</small>
                </li>
                <li>
                  art. 6 ust. 1 lit. b<br />
                  <small>{t("privacy:art-6-b")}</small>
                </li>
                <li>
                  art. 6 ust. 1 lit. f<br />
                  <small>{t("privacy:art-6-f")}</small>
                </li>
              </ul>
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:10.2018")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:16.2004")}</Typography>
          </li>
          <li>
            <Typography variant="subtitle1">{t("privacy:4.1997")}</Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("privacy:chapter-12")}>
        <Typography variant="subtitle1">
          <strong>{t("privacy:personal-data-provided")}</strong>
        </Typography>
        <Typography variant="subtitle1">{t("privacy:in-principle")}</Typography>
        <Typography variant="subtitle1">{t("privacy:exception")}</Typography>
        <Typography variant="subtitle1">
          <strong>{t("privacy:anonym-data-automatic")}</strong>
        </Typography>
        <Typography variant="subtitle1">{t("privacy:static-data")}</Typography>
      </CmsArticle>
      <CmsArticle heading={t("privacy:chapter-13")}>
        <Typography variant="subtitle1">
          {t("privacy:website-collects-processes")}
        </Typography>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:right-access-personal-data")}</strong>
              <br />
              {t("privacy:right-access-personal-data-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:right-rectify-data")}</strong>
              <br />
              {t("privacy:right-rectify-data-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:right-delete-data")}</strong>
              <br />
              {t("privacy:right-delete-data-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:right-limit-data")}</strong>
              <br />
              {t("privacy:right-limit-data-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:right-carrying-data")}</strong>
              <br />
              {t("privacy:right-carrying-data-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:right-contribution-data")}</strong>
              <br />
              {t("privacy:right-contribution-data-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:right-complain-data")}</strong>
              <br />
              {t("privacy:right-complain-data-text")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("privacy:chapter-14")}>
        <Typography variant="subtitle1">
          {t("privacy:chapter-14-desc")}
        </Typography>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:post-address")}</strong> - RealN Sp.zo.o, ul.
              Lęborska 3B / 80-386 Gdańsk
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:electronic-post-address")}</strong> -
              info@realn.pl
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:phone-connection")}</strong> - +48 668 001 756
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("privacy:contact-form")}</strong> -{" "}
              {t("privacy:available-at")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("privacy:chapter-15")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:storage-restrictions")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:admin-is-not-liable")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("privacy:chapter-16")}>
        <Typography variant="subtitle1">
          {t("privacy:chapter-16-desc")}
        </Typography>
      </CmsArticle>
      <CmsArticle heading={t("privacy:chapter-17")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:chapter-17-paragraph-1")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:chapter-17-paragraph-2")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:chapter-17-paragraph-3")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("privacy:chapter-17-paragraph-4")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
    </Grid>
  );
};

export default PrivacyPolicy;
