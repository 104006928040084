import ContactRequestsTable from "components/views/profile/ContactRequestsTable";
import { useTranslation } from "react-i18next";
import ProfileContainer from "components/views/profile/components/ProfileContainer";
import { Typography } from "@mui/material";

const ContactRequests = () => {
  const { t } = useTranslation();

  return (
    <ProfileContainer>
      <Typography variant="h6" component="h3" sx={{ mb: 3 }}>
        <strong>{t("profile:my-requests")}</strong>
      </Typography>
      <ContactRequestsTable />
    </ProfileContainer>
  );
};

export default ContactRequests;
