import SmallContact from "../../utils/SmallContact";
import mock from "../../../../images/mock.webp";
import getit from "../../../../images/getit.webp";
import pic2 from "../../../../images/pic2.webp";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import CmsBanner from "components/reusable/cms/CmsBanner";
import CmsList from "components/reusable/cms/CmsList";
import background from "../../../../images/whybuybg.webp";

const Whybuy = () => {
  const { t } = useTranslation();
  const backgroundImage = `linear-gradient(
    176deg,
    rgba(52, 52, 52, 1) 0%,
    rgba(0, 0, 0, 0) 67%
  ),url(${background})`;

  return (
    <Grid>
      <Box mb={10}>
        <CmsBanner
          heading={t("buy:why-is-it-worth")}
          imgSrc={backgroundImage}
        />
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={10}
      >
        <Grid item xs={11} sm={4}>
          <Typography variant="h4" component="h2">
            <strong> {t("buy:why-is-it-worth")}</strong>
          </Typography>
        </Grid>
        <Grid item xs={11} sm={4}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap={10}
          >
            <Grid item>
              <Typography variant="h5" component="p" mb={5}>
                {t("buy:dream-property-with-us")}
              </Typography>
              <Typography variant="subtitle1">
                {" "}
                {t("buy:we-update-offers")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="p" mb={5}>
                {" "}
                {t("buy:local-agent")}{" "}
              </Typography>
              <Typography variant="subtitle1">
                {" "}
                {t("buy:your-agent")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="p" mb={5}>
                {" "}
                {t("buy:no-commission")}{" "}
              </Typography>
              <Typography variant="subtitle1">
                <strong>{t("buy:no-commission-charges")}</strong>{" "}
                {t("buy:thanks-to-unique-technology")}
              </Typography>
            </Grid>{" "}
          </Grid>
        </Grid>
      </Grid>
      <SmallContact />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={10}
        mt={10}
        mb={10}
      >
        <Grid item xs={11} sm={4}>
          <Typography variant="h4" component="h2">
            <strong> {t("buy:how-it-works")}</strong>
          </Typography>
        </Grid>
        <Grid item xs={11} sm={4}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap={10}
          >
            <Grid item>
              <Typography variant="h5" component="p" mb={5}>
                {t("buy:stay-up-to-date")}
              </Typography>
              <Typography variant="subtitle1">
                {" "}
                {t("buy:visiting-via-website")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="p" mb={5}>
                {" "}
                {t("buy:saw-your-time")}{" "}
              </Typography>
              <Typography variant="subtitle1">
                {" "}
                {t("buy:based-interview")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="p" mb={5}>
                {" "}
                {t("buy:make-an-offer")}{" "}
              </Typography>
              <Typography variant="subtitle1">{t("buy:simple-way")}</Typography>
            </Grid>{" "}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: "50px 0", backgroundColor: "#f4f6f7" }}
        gap={5}
      >
        <Grid item xs={11} sm={5}>
          <img
            src={mock}
            alt="site mockup"
            style={{ height: "auto", width: "100%" }}
          ></img>
        </Grid>
        <Grid item xs={11} sm={5} sx={{ padding: "0 20px" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gap={5}
          >
            <Grid item xs={12}>
              <Typography variant="h4" component="h2">
                {t("buy:presentation-invite")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {" "}
              <Typography variant="subtitle1">
                {t("buy:update-offers-every")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <img
                src={getit}
                alt="store img"
                style={{ height: "auto", width: "100%" }}
              ></img>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={10}
        mb={10}
        gap={10}
      >
        <Grid item xs={11} sm={5} sx={{ textAlign: "center" }}>
          <img
            src={pic2}
            alt="home to buys"
            style={{
              width: "50%",
              border: "20px solid #f4f6f7",
              borderRadius: "15px",
            }}
          ></img>
        </Grid>
        <Grid item xs={11} sm={5} sx={{ padding: "0 20px" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gap={5}
          >
            <Grid item xs={12}>
              <Typography variant="h3" component="h2">
                {t("buy:selling-old-house-or-apartment")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" component="h3">
                {t("buy:proposition-for-you")}...
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t("buy:pay-only")}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {t("buy:tools-like")}:
              </Typography>
              <CmsList>
                <li>{t("buy:3D-walks")}</li>
                <li>{t("buy:live-presentations-video")}</li>
                <li>{t("buy:digital-marketing-campaigns")}</li>
              </CmsList>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" component="h2">
                {t("buy:we-are-effective")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Whybuy;
