import { useTranslation } from "react-i18next";
import SearchWithState from "./Searcher/SearchWithState";
import { Grid, Typography } from "@mui/material";

const SmallContact = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      xs={12}
      justifyContent={"center"}
      sx={{ backgroundColor: "#f4f6f7", padding: "40px 10px" }}
      mt={10}
    >
      <Grid item container xs={12} sm={8} alignItems={"center"}>
        <Grid item sm={6} xs={12} mb={2}>
          <Typography variant="h4" component="h2" mb={2}>
            <strong>{t("utils:agent-is-here-to-help")}</strong>
          </Typography>
          <Typography variant="subtitle1">{t("utils:start-now")}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="subtitle1" mb={1}>
            {t("utils:where-you-search")}
          </Typography>
          <SearchWithState />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SmallContact;
