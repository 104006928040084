import React, { PropsWithChildren } from "react";
import { Grid, Typography } from "@mui/material";

interface Props {
  heading: string;
}

const CmsSubArticle = ({ heading, children }: Props & PropsWithChildren) => {
  return (
    <>
      <Grid item container justifyContent="center" gap={2}>
        <Grid item>
          <Typography variant="h6" component="h3">
            <strong>{heading}</strong>
          </Typography>
        </Grid>
        {children}
      </Grid>
    </>
  );
};

export default CmsSubArticle;
