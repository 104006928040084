import { Grid } from "@mui/material";
import UserRoleFeatureFlag from "components/reusable/UserRoleFeatureFlag";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { UserRoleEnum } from "utils/enums";

type Props = {};

const NAV_LINKS: {
  to: string;
  label: string;
  allowedRoles: UserRoleEnum[];
  exact?: boolean;
}[] = [
  {
    to: "/profile",
    label: "profile:profile",
    allowedRoles: Object.values(UserRoleEnum),
    exact: true,
  },
  {
    to: "/profile/preferences",
    label: "profile:preferences",
    allowedRoles: Object.values(UserRoleEnum),
  },
  // { to: "/profile/messages", label: "profile:messages" },
  {
    to: "/profile/favorites",
    label: "profile:favorites",
    allowedRoles: Object.values(UserRoleEnum),
  },
  {
    to: "/profile/search-criterias",
    label: "profile:my-search-criterias",
    allowedRoles: Object.values(UserRoleEnum),
  },
  {
    to: "/profile/contact-requests",
    label: "profile:my-requests",
    allowedRoles: Object.values(UserRoleEnum),
  },
  {
    to: "/profile/offers",
    label: "profile:offers",
    allowedRoles: [UserRoleEnum.AGENT, UserRoleEnum.ADMIN],
  },
  {
    to: "/profile/tokens",
    label: "profile:tokens",
    allowedRoles: [UserRoleEnum.ADMIN],
  },
];

const ProfileNav = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      sx={{
        height: "80px",
        justifyContent: "center",
        alignItems: "stretch",
        backgroundColor: "common.white",
        flexWrap: "nowrap",
      }}
    >
      {NAV_LINKS.map((link) => (
        <UserRoleFeatureFlag key={link.to} allowedRoles={link.allowedRoles}>
          <Grid
            item
            sx={{
              flexBasis: "112px",
              flexShrink: { xs: 1, sm: 0 },
              flexGrow: 0,
              a: {
                display: "block",
                boxSizing: "border-box",
                width: "100%",
                height: "100%",
                py: 4,
                textAlign: "center",
                color: "secondary.dark",
                fontSize: {
                  xs: "11px",
                  sm: "16px",
                },
              },
              "a:hover, a.active": {
                backgroundColor: "grey.100",
              },
              "a:hover": {
                borderBottom: "2px solid",
                borderColor: "secondary.dark",
              },
            }}
          >
            <NavLink
              to={link.to}
              className={({ isActive }) => (isActive ? "active" : "")}
              end={link.exact}
            >
              {t(link.label)}
            </NavLink>
          </Grid>
        </UserRoleFeatureFlag>
      ))}
    </Grid>
  );
};

export default ProfileNav;
