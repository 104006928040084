import { LoadingButton } from "@mui/lab";
import { Button, Dialog, Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";

interface ConfirmationDialogProps {
  open: boolean;
  text?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
  isLoading?: boolean;
}

const ConfirmationDialog = ({
  open,
  text,
  onClose,
  onConfirm,
  cancelButtonText,
  confirmButtonText,
  isLoading,
}: ConfirmationDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          style: { backgroundColor: "transparent" },
        },
      }}
    >
      <Grid
        container
        sx={{
          position: "relative",
          justifyContent: "center",
          columnGap: 6,
          rowGap: 3,
          p: 8,
          maxWidth: 420,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            borderRadius: "50%",
          }}
        >
          <IoMdClose size={20} />
        </IconButton>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            <strong>{text}</strong>
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Button onClick={onClose} color="secondary">
            {cancelButtonText || t("utils:cancel")}
          </Button>
        </Grid>
        <Grid item xs="auto">
          <LoadingButton
            onClick={onConfirm}
            size="medium"
            loading={isLoading}
            sx={{ ...(isLoading ? { color: "transparent !important" } : {}) }}
          >
            {confirmButtonText || t("profile:delete")}
          </LoadingButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConfirmationDialog;
