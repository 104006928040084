import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Typography } from "@mui/material";
import CmsBanner from "components/reusable/cms/CmsBanner";
import background from "../../../../images/sellrentbg.webp";
import CmsArticle from "components/reusable/cms/CmsArticle";
import CmsList from "components/reusable/cms/CmsList";

const BuyOrRent = () => {
  const { t } = useTranslation();

  return (
    <Grid container gap={10} paddingBottom={20}>
      <CmsBanner
        heading={t("rent:buying-or-renting")}
        imgSrc={`url(${background})`}
      />
      <CmsArticle heading={t("rent:you-deciding")}>
        <Grid item>
          <Typography variant="subtitle1">
            {t("rent:maybe-you-ready")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            <strong>{t("rent:one-important-question")}</strong>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">{t("rent:each-of-us")}</Typography>
        </Grid>
        <Grid item></Grid>
      </CmsArticle>
      <CmsArticle heading={t("rent:question-1")}>
        <Grid item>
          <Typography variant="subtitle1">
            {t("rent:buying-and-renting")}
          </Typography>
        </Grid>
      </CmsArticle>
      <CmsArticle heading={t("rent:question-2")}>
        <Grid item>
          <Typography variant="subtitle1">
            {t("rent:buying-and-renting")}
          </Typography>
        </Grid>
      </CmsArticle>
      <CmsArticle heading={t("rent:question-3")}>
        <Grid item>
          <Typography variant="subtitle1">{t("rent:rent-vs-buy")}</Typography>
        </Grid>
      </CmsArticle>
      <CmsArticle heading={t("rent:buying-renting-advatages")}>
        <Grid item>
          <Typography variant="subtitle1">
            {t("rent:decide-on-renting")}
          </Typography>
        </Grid>
      </CmsArticle>
      <CmsArticle heading={t("rent:aparment-advatages")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("rent:house-repairs")} </strong>
              {t("rent:house-repairs-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {" "}
              <span className="text-bold">
                {t("rent:monthly-expenses-house")}{" "}
              </span>
              {t("rent:monthly-expenses-house-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <span className="text-bold">{t("rent:flexibility")} </span>
              {t("rent:flexibility-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("rent:investment-opportunities")} </strong>
              {t("rent:investment-opportunities-text")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("rent:apartament-disadvantages")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("rent:temporary")} </strong>
              {t("rent:temporary-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {" "}
              <strong>{t("rent:uncertainty")} </strong>
              {t("rent:uncertainty-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("rent:possible-rent-increase")}</strong>
              {t("rent:possible-rent-increase-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("rent:lack-of-equity")} </strong>
              {t("rent:lack-of-equity-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("rent:house-shell-state")} </strong>
              {t("rent:house-shell-state-text")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("rent:advantages-of-buying-a-house")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("rent:building-capital")} </strong>
              {t("rent:building-capital-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {" "}
              <strong>{t("rent:adapt-your-needs")} </strong>
              {t("rent:adapt-your-needs-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("rent:stability")}</strong>
              {t("rent:stability-text")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("rent:disadvantages-house")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("rent:closing-costs")} </strong>
              {t("rent:closing-costs-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {" "}
              <strong>{t("rent:house-worth")} </strong>
              {t("rent:house-worth-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("rent:house-expense")}</strong>
              {t("rent:house-expense-text")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("rent:investment-restrictions")}</strong>
              {t("rent:investment-restrictions-text")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("rent:conclusion")}>
        <Grid item>
          <Typography variant="subtitle1">
            {t("rent:conclusion-text")}
          </Typography>
        </Grid>
      </CmsArticle>
      <Grid
        container
        xs={12}
        style={{ backgroundColor: "#f4f6f7", padding: "30px" }}
        justifyContent="center"
        gap={10}
      >
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h3">{t("rent:check-articles")}</Typography>
        </Grid>
        <Grid item container xs={6} sm={4} sx={{ justifyContent: "flex-end" }}>
          <Paper sx={{ padding: "30px 10px", maxWidth: "280px" }}>
            <Link
              to="/guides/how-to-buy-a-house"
              style={{ textDecoration: "none", color: "#4f4f4f" }}
            >
              <Grid item mb={5}>
                <Typography variant="h4">
                  {t("rent:how-to-buy-house")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" style={{ color: "#4396ca" }}>
                  {t("rent:find-out-more")}
                </Typography>
              </Grid>
            </Link>
          </Paper>
        </Grid>
        <Grid item container xs={6} sm={4}>
          <Paper sx={{ padding: "30px 10px", maxWidth: "280px" }}>
            <Link
              to="/guides/real-estate-commision"
              style={{ textDecoration: "none", color: "#4f4f4f" }}
            >
              <Grid item mb={5}>
                <Typography variant="h4">
                  {t("rent:about-real-estate")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" style={{ color: "#4396ca" }}>
                  {t("rent:find-out-more")}
                </Typography>
              </Grid>
            </Link>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BuyOrRent;
