import "./sellwith.scss";
import pic5 from "../../../../images/pic5.webp";
import pic4 from "../../../../images/pic4.webp";
import mock from "../../../../images/mock.webp";
import EstateSlider from "../../utils/EstateSlider";
import SmallContact from "../../utils/SmallContact";
import { useTranslation } from "react-i18next";
import { FaMedal, FaPeopleArrows } from "react-icons/fa";
import { GiClassicalKnowledge } from "react-icons/gi";
import SearchWithState from "components/views/utils/Searcher/SearchWithState";
import { useAppDispatch } from "components/store/configureStore";
import { useEffect } from "react";
import { searchOffersAsync } from "components/views/offers/catalogSlice";
import variables from "variables";
import CmsBanner from "components/reusable/cms/CmsBanner";
import { Grid, Typography } from "@mui/material";

const SellWith = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(searchOffersAsync());
  }, []);

  return (
    <>
      {" "}
      <CmsBanner
        heading={`${t("sell:sell-for-more")} ${t("sell:and-save")}`}
        text={`${t("sell:realn-agents-has-experience")} ${t(
          "sell:to-start"
        )} ${`tel: ${variables.realnPhone}`} ${t("sell:or")} ${t(
          "sell:text-us"
        )} ${t("sell:we-contact")}`}
        imgSrc={pic5}
        imgInRow={true}
      />
      <Grid
        container
        justifyContent={"center"}
        xs={12}
        sx={{
          backgroundColor: "#f4f6f7",
          width: "100%",
          padding: "30px 0",
        }}
        mt={10}
        mb={10}
      >
        <Grid
          item
          container
          direction="row"
          sm={10}
          xs={12}
          justifyContent={"center"}
          sx={{
            padding: "30px 10px",
            textAlign: "center",
          }}
          gap={3}
        >
          <Grid item xs={12}>
            <Typography variant="h3" component="h2" mb={2}>
              <strong>{t("sell:why-is-it-worth")}</strong>
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            xs={12}
            mt={5}
          >
            <Grid item xs={12} sm={3}>
              <FaMedal style={{ fontSize: "45px" }} />
              <Typography variant="h5" component="h4" mt={3} mb={3}>
                {t("sell:best-agents")}
              </Typography>
              <Typography variant="subtitle1">{t("sell:top-one")}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              sx={{ padding: { xs: "20px 0", sm: "0 10px" } }}
            >
              <GiClassicalKnowledge style={{ fontSize: "45px" }} />
              <Typography variant="h5" component="h4" mt={3} mb={3}>
                {t("sell:experts")}
              </Typography>
              <Typography variant="subtitle1">
                {t("sell:experience")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FaPeopleArrows style={{ fontSize: "45px" }} />
              <Typography variant="h5" component="h4" mt={3} mb={3}>
                {t("sell:personal")}
              </Typography>
              <Typography variant="subtitle1">
                {t("sell:agency-personal")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        mb={10}
        gap={10}
      >
        <Grid item xs={11} sm={10} sx={{ textAlign: "center" }}>
          <Typography variant="h3" component="h2" mt={3} mb={3}>
            <strong>{t("sell:get-a-marketing")}</strong>
          </Typography>
        </Grid>
        <Grid item xs={11} sm={5} sx={{ width: { xs: "100%", sm: "90%" } }}>
          <img
            src={mock}
            alt="ipad"
            style={{ width: "100%", minHeight: "500px", objectFit: "cover" }}
          ></img>
        </Grid>
        <Grid item xs={11} sm={5}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap={10}
          >
            <Grid item xs={12}>
              <Typography variant="h5" component="p" mb={5}>
                {t("sell:more-eyes-on-house")}
              </Typography>
              <Typography variant="subtitle1">
                {" "}
                {t("sell:when-you-place-offer")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" component="p" mb={5}>
                {" "}
                {t("sell:preferred-position")}
              </Typography>
              <Typography variant="subtitle1">
                {" "}
                {t("sell:first-week")}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <Typography variant="h5" component="p" mb={5}>
                {" "}
                {t("sell:digital-campaigns")}
              </Typography>
              <Typography variant="subtitle1">
                {" "}
                {t("sell:each-offer")}
              </Typography>
            </Grid>{" "}
            <Grid item xs={12}>
              <Typography variant="h5" component="p" mb={5}>
                {" "}
                {t("sell:interactive-tours")}
              </Typography>
              <Typography variant="subtitle1">
                {t("sell:each-house")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={10}
        mt={10}
        mb={10}
      >
        <Grid item xs={11} sm={5}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5" component="p" mb={5}>
                {t("sell:high-quality-sale")}
              </Typography>
              <Typography variant="subtitle1">{t("sell:premium")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11} sm={5} sx={{ width: { xs: "100%", sm: "90%" } }}>
          <img
            src={pic5}
            alt="luxury apartment"
            style={{ width: "100%", height: "400px", objectFit: "cover" }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={10}
        mb={10}
        gap={10}
      >
        <Grid
          item
          xs={11}
          sm={5}
          sx={{ width: "90%", display: { xs: "none", sm: "block" } }}
        >
          <img
            src={pic4}
            alt="luxury apartment"
            style={{ width: "100%", height: "400px", objectFit: "cover" }}
          />
        </Grid>
        <Grid item xs={11} sm={5}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap={10}
          >
            <Grid item container gap={5}>
              <Grid item>
                <Typography variant="h5" component="p">
                  {t("sell:ready-to-go")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {t("sell:today-connect-with-agent")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SearchWithState />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* 
      <div className="sellwith-opinions">
        <ImQuotesLeft />
        <h3>{t("sell:opinions")}</h3>
        <p>
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          alias voluptatem accusamus temporibus voluptatum rerum labore
          cupiditate sapiente incidunt facilis!"
        </p>
        <p>Sebastian Zimnol</p>
        <ImQuotesRight />
      </div>
    */}
      <Grid item sx={{ margin: "0 15%" }} mt={10}>
        <EstateSlider />
      </Grid>
      <SmallContact />
    </>
  );
};

export default SellWith;
