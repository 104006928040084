import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { ResponseOffer } from "components/models/offer";
import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import { duplicateOfferAsync } from "components/views/offers/catalogSlice";
import ConfirmationDialog from "components/views/utils/ConfirmationDialog";

interface EditOfferButtonProps {
  offer: ResponseOffer;
}

const DuplicationOfferButton = ({ offer }: EditOfferButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const userId = useAppSelector((state) => state.account.user?.id);

  const isDuplicating = useAppSelector(
    (state) => state.catalog.status === "pendingDuplicateOffer"
  );

  const handleDuplicate = async () => {
    if (!offer.id) return;

    const res = await dispatch(duplicateOfferAsync(offer.id));
    if (res.type === "catalog/duplicateOfferAsync/fulfilled")
      navigate(`/profile/offers`);
  };

  if (!offer?.user || offer.user.id !== userId) return null;

  return (
    <>
      <Button size="large" fullWidth onClick={() => setIsOpen(true)}>
        {t("profile:duplicate-offer")}
      </Button>
      <ConfirmationDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={handleDuplicate}
        isLoading={isDuplicating}
        text={t("profile:sure-to-duplicate-offer")}
        confirmButtonText={t("profile:duplicate")}
        cancelButtonText={t("profile:cancel")}
      />
    </>
  );
};

export default DuplicationOfferButton;
