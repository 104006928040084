import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { HiOutlineMagnifyingGlassCircle } from "react-icons/hi2";
import TooltipComponent from "components/reusable/Tooltip/Tooltip";
import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import { IoMdClose } from "react-icons/io";
import { DATE_FORMAT } from "utils/constants";

import {
  deleteContactRequestsAsync,
  fetchContactRequestsAsync,
} from "components/views/LoginRegister/accountSlice";
import { useTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  Dialog,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import ProfileTableLoader from "components/views/profile/components/ProfileTableLoader";
import ProfileTable from "components/views/profile/components/ProfileTable";
import { LoadingButton } from "@mui/lab";

export interface ContactRequest {
  id: number;
  offer_id: string;
  message: string;
  customer_email: string;
  customer_phone: string;
  created_at: string;
  updated_at: string;
}

enum DialogFunction {
  Delete = "DELETE",
  Show = "SHOW",
}

const ContactRequestsTable = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [currentRequest, setCurrentRequest] = useState<ContactRequest | null>(
    null
  );
  const [dialogToDisplay, setDialogToDisplay] = useState<DialogFunction>();
  const [contactRequests, setContactRequests] = useState<ContactRequest[]>([]);
  const status = useAppSelector((state) => state.account.status);
  const dispatch = useAppDispatch();

  const isLoading = status === "pendingFetchContactRequests";
  const isDeleting = status === "pendingDeleteContactRequests";

  const handleClose = () => {
    setOpen(false);
  };

  const deleteRequest = (id: number) => {
    dispatch(deleteContactRequestsAsync(id)).then(() => {
      setContactRequests((prevState) =>
        prevState.filter((element) => element.id !== id)
      );
      handleClose();
    });
  };

  const openDialog = (
    dialogFunction: DialogFunction,
    request: ContactRequest
  ) => {
    setCurrentRequest(request);
    setDialogToDisplay(dialogFunction);
    setOpen(true);
  };

  const showMessageDialog = (request: ContactRequest) => {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            <strong>{t("profile:message-details")}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <strong>{t("profile:request-email")}</strong>:{" "}
          {request.customer_email}
        </Grid>
        <Grid item xs={12}>
          <strong>{t("profile:request-phone")}</strong>:{" "}
          {request.customer_phone}
        </Grid>
        <Grid item xs={12}>
          <strong>{t("profile:request-created")}</strong>:{" "}
          {dayjs(request.created_at).format(DATE_FORMAT)}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            border: "1px solid",
            borderColor: "gray",
            borderRadius: "5px",
            minHeight: 100,
            p: 2,
          }}
        >
          {request.message}
        </Grid>
        <Grid item xs="auto">
          <Button onClick={() => setDialogToDisplay(DialogFunction.Delete)}>
            {t("profile:delete-request")}
          </Button>
        </Grid>
      </>
    );
  };

  const deleteRequestDialog = (request: ContactRequest) => {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            <strong>
              {t("profile:sure-to-delete")}
              <br />{" "}
              {t("profile:delete-from", { email: request.customer_email })}?
            </strong>
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Button
            color="secondary"
            onClick={() => setDialogToDisplay(DialogFunction.Show)}
          >
            {t("profile:cancel")}
          </Button>
        </Grid>
        <Grid item xs="auto">
          <LoadingButton
            onClick={() => deleteRequest(request.id)}
            loading={isDeleting}
            sx={{ ...(isDeleting ? { color: "transparent !important" } : {}) }}
          >
            {t("profile:delete")}
          </LoadingButton>
        </Grid>
      </>
    );
  };

  const showDialog = (request: ContactRequest) => {
    switch (dialogToDisplay) {
      case DialogFunction.Show:
        return showMessageDialog(request);
      case DialogFunction.Delete:
        return deleteRequestDialog(request);
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(fetchContactRequestsAsync()).then((data: any) => {
      setContactRequests(data.payload.data);
    });
  }, [dispatch]);

  if (isLoading) return <ProfileTableLoader />;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        slotProps={{
          backdrop: {
            style: { backgroundColor: "transparent" },
          },
        }}
      >
        <Grid
          container
          sx={{
            position: "relative",
            justifyContent: "center",
            columnGap: 6,
            rowGap: 3,
            p: 8,
            maxWidth: 420,
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              borderRadius: "50%",
            }}
          >
            <IoMdClose size={20} />
          </IconButton>
          {currentRequest && showDialog(currentRequest)}
        </Grid>
      </Dialog>
      <ProfileTable
        columns={[
          { id: "customer_email", title: t("profile:request-email") },
          { id: "customer_phone", title: t("profile:request-phone") },
          {
            id: "created_at",
            title: t("profile:request-created"),
            hidden: ["xs"],
          },
          {
            id: "updated_at",
            title: t("profile:request-updated"),
            hidden: ["xs", "sm"],
          },
          { id: "actions", title: t("profile:request-actions") },
        ]}
        rows={contactRequests.map((request) => ({
          id: request.id,
          columns: {
            customer_email: request.customer_email,
            customer_phone: request.customer_phone,
            created_at: dayjs(request.created_at).format(DATE_FORMAT),
            updated_at: dayjs(request.updated_at).format(DATE_FORMAT),
            actions: (
              <TooltipComponent
                WrappedComponent={
                  <IconButton
                    aria-label="edit"
                    onClick={() => openDialog(DialogFunction.Show, request)}
                    sx={{ borderRadius: "50%" }}
                  >
                    <HiOutlineMagnifyingGlassCircle size={24} />
                  </IconButton>
                }
                message={t("profile:show-message")}
              />
            ),
          },
        }))}
      />
    </>
  );
};

export default ContactRequestsTable;
