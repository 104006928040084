import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import { useEffect, useState } from "react";
import {
  deleteSearchCriteriaAsync,
  fetchSearchCriteriasAsync,
} from "components/views/LoginRegister/accountSlice";
import { Typography } from "@mui/material";

import { SearchCriteria } from "components/models/offer";
import SearchCriteriasTable from "components/views/profile/SearchCriteriasTable";
import { useTranslation } from "react-i18next";
import ProfileContainer from "components/views/profile/components/ProfileContainer";
import ConfirmationDialog from "components/views/utils/ConfirmationDialog";

const SearchCriterias = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { favoriteSearchCriterias, status, user } = useAppSelector(
    (state) => state.account
  );
  const [criteriaForDeletion, setCriteriaForDeletion] = useState<
    SearchCriteria["id"] | null
  >(null);

  useEffect(() => {
    if (!user?.id) return;
    dispatch(fetchSearchCriteriasAsync());
  }, [user?.id, dispatch]);

  const handleDelete = async () => {
    if (!criteriaForDeletion || isDeleting) return;
    await dispatch(deleteSearchCriteriaAsync(criteriaForDeletion));
    setCriteriaForDeletion(null);
  };

  const isLoading = status === "pendingFetchSearchCriterias";
  const isDeleting = status === "pendingDeleteSearchCriteria";

  return (
    <ProfileContainer>
      <Typography variant="h6" component="h3" sx={{ mb: 3 }}>
        <strong>{t("profile:my-search-criterias")}</strong>
      </Typography>
      <SearchCriteriasTable
        favoriteSearchCriterias={favoriteSearchCriterias}
        onDelete={setCriteriaForDeletion}
        isLoading={isLoading}
      />
      <ConfirmationDialog
        open={!!criteriaForDeletion}
        text={t("profile:sure-to-delete-search-criteria")}
        onClose={() => setCriteriaForDeletion(null)}
        onConfirm={handleDelete}
        cancelButtonText={t("profile:cancel")}
        confirmButtonText={t("profile:delete")}
        isLoading={isDeleting}
      />
    </ProfileContainer>
  );
};

export default SearchCriterias;
