import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import { useEffect, useState } from "react";
import {
  fetchSearchCriteriasAsync,
  removeOfferFromFavoritesAsync,
} from "components/views/LoginRegister/accountSlice";
import { Typography } from "@mui/material";

import FavoritesTable from "components/views/profile/FavoritesTable";
import { FavoriteOffer } from "components/models/offer";
import { useTranslation } from "react-i18next";
import ProfileContainer from "components/views/profile/components/ProfileContainer";
import ConfirmationDialog from "components/views/utils/ConfirmationDialog";

const Favorites = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { favoriteOffers, status, user } = useAppSelector(
    (state) => state.account
  );
  const [offerForDeletion, setOfferForDeletion] = useState<
    FavoriteOffer["id"] | null
  >(null);

  useEffect(() => {
    if (!user?.id) return;
    dispatch(fetchSearchCriteriasAsync());
  }, [user?.id, dispatch]);

  const handleDelete = async () => {
    if (!offerForDeletion || isDeleting) return;

    await dispatch(removeOfferFromFavoritesAsync(offerForDeletion));
    setOfferForDeletion(null);
  };

  const isLoading = status === "pendingGetUserFavoriteOffers";
  const isDeleting = status === "pendingRemoveOfferFromFavorites";

  const handleClickDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    e.preventDefault();
    setOfferForDeletion(id);
  };

  return (
    <ProfileContainer>
      <Typography variant="h6" component="h3" sx={{ mb: 3 }}>
        <strong>{t("profile:favorites")}</strong>
      </Typography>
      <FavoritesTable
        favoriteOffers={favoriteOffers}
        onDelete={handleClickDelete}
        isLoading={isLoading}
      />
      <ConfirmationDialog
        open={!!offerForDeletion}
        text={t("profile:sure-to-delete-favorite-offer")}
        onClose={() => setOfferForDeletion(null)}
        onConfirm={handleDelete}
        cancelButtonText={t("profile:cancel")}
        confirmButtonText={t("profile:delete")}
        isLoading={isDeleting}
      />
    </ProfileContainer>
  );
};

export default Favorites;
