import { useTranslation } from "react-i18next";
import SmallContact from "components/views/utils/SmallContact";
import { Grid, Typography } from "@mui/material";
import CmsBanner from "components/reusable/cms/CmsBanner";
import chooseagent from "../../../../../images/chooseagentbg.webp";
import CmsArticle from "components/reusable/cms/CmsArticle";
import CmsSubArticle from "components/reusable/cms/CmsSubArticle";

const ChooseAgent = () => {
  const { t } = useTranslation();
  const backgroundImage = `url(${chooseagent})`;
  return (
    <Grid container mb={10} gap={10}>
      <CmsBanner
        heading={t("resources:how-to-choose-agent-for")}
        imgSrc={backgroundImage}
        text={t("resources:top-15")}
      />
      <Grid container gap={6}>
        <CmsArticle
        // sx={{ margin: { xs: "0", md: "0 30%" }, padding: "0px 60px" }}
        >
          <Typography variant="subtitle1" component="h2">
            {t("resources:not-knowing")}
          </Typography>
          <CmsSubArticle heading={t("resources:main-job")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:main-job-text")}
              </Typography>
            </Grid>
          </CmsSubArticle>
          <CmsSubArticle heading={t("resources:how-many-offers")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:how-many-offers-text")}
              </Typography>
            </Grid>
          </CmsSubArticle>
          <CmsSubArticle heading={t("resources:former-customers")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:former-customers-text")}
              </Typography>
            </Grid>
          </CmsSubArticle>
          <CmsSubArticle heading={t("resources:customer-filled-complain")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:if-uncomfortable-question")}
              </Typography>
            </Grid>
          </CmsSubArticle>
          <CmsSubArticle heading={t("resources:what-is-your-fee")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:standard-agencies")}
              </Typography>
            </Grid>
          </CmsSubArticle>
          <CmsSubArticle heading={t("resources:what-services")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:make-a-list")}
              </Typography>
            </Grid>
          </CmsSubArticle>
          <CmsSubArticle heading={t("resources:am-i-required")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:many-clients")}
              </Typography>
            </Grid>
          </CmsSubArticle>
          <CmsSubArticle heading={t("resources:how-many-transactions")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:difficult-offers")}
              </Typography>
            </Grid>
          </CmsSubArticle>
          <CmsSubArticle heading={t("resources:who-work-with-me")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:agent-is-supported")}
              </Typography>
            </Grid>
          </CmsSubArticle>
          <CmsSubArticle heading={t("resources:will-you-show-all-offers")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:will-you-show-all-offers")}
              </Typography>
            </Grid>
          </CmsSubArticle>
          <CmsSubArticle heading={t("resources:how-quikly")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:best-offers")}
              </Typography>
            </Grid>
          </CmsSubArticle>
          <CmsSubArticle heading={t("resources:represent-buyers-and-sellers")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:double-agent")}
              </Typography>
            </Grid>
          </CmsSubArticle>
          <CmsSubArticle heading={t("resources:what-sets-you-apart")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:look-for-an-agent")}
              </Typography>
            </Grid>
          </CmsSubArticle>
          <CmsSubArticle heading={t("resources:what-if")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:agents-get-paid")}
              </Typography>
            </Grid>
          </CmsSubArticle>
          <CmsSubArticle heading={t("resources:get-reference")}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("resources:each-agent")}
              </Typography>
            </Grid>
          </CmsSubArticle>
        </CmsArticle>
      </Grid>
      <SmallContact />
    </Grid>
  );
};

export default ChooseAgent;
