import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  alpha,
} from "@mui/material";
import React from "react";
import ProfileNav from "./ProfileNav";

interface ProfileContainerProps {
  children?: React.ReactNode;
  isLoading?: boolean;
}

const ProfileContainer = ({ children, isLoading }: ProfileContainerProps) => {
  return (
    <Stack sx={{ mt: "82px" }}>
      <ProfileNav />
      <Grid
        container
        sx={{
          backgroundColor: { xs: "common.white", md: "grey.100" },
          pt: 4,
          pb: 12,
          justifyContent: "center",
        }}
      >
        <Grid item sx={{ flexBasis: "940px" }}>
          <Paper
            elevation={0}
            sx={{
              p: 6,
              borderRadius: "30px",
              position: "relative",
              boxSizing: "border-box",
              minHeight: 400,
            }}
          >
            {children}
            {isLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: (theme) =>
                    alpha(theme.palette.common.white, 0.5),
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ProfileContainer;
