import { Link } from "react-router-dom";
import guide from "../../../../images/icons/guide.png";
import costs from "../../../../images/icons/costs.png";
import foreign from "../../../../images/icons/foreign.png";
import steps from "../../../../images/icons/steps.png";
import choose from "../../../../images/icons/choose.png";
import { useAppDispatch, useAppSelector } from "../../../store/configureStore";
import { setPage } from "../../../store/utilitySlice";
import { useTranslation } from "react-i18next";
import CmsBanner from "components/reusable/cms/CmsBanner";
import { Box, Grid, Paper, Tab, Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext/TabContext";
import { useState } from "react";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import theme from "theme";

const BuyGuide = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      mt={10}
    >
      <Grid item xs={12}>
        <CmsBanner
          heading={t("resources:your-complete-guide")}
          imgSrc={guide}
          text={t("resources:buying-or-selling")}
          imgInRow={true}
        />
      </Grid>
      <Grid item xs={10}>
        <TabContext value={value}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label={t("resources:real-estate-guide")}
              value="1"
              sx={{ color: theme.palette.primary.light }}
            />
            <Tab
              label={t("resources:sales-guide")}
              value="2"
              sx={{ color: theme.palette.primary.light }}
            />
          </TabList>
          <TabPanel value="1">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              xs={12}
            >
              <Grid item xs={12} sm={6} md={4} sx={{ padding: "10px" }}>
                <Paper
                  sx={{
                    minHeight: "318px",
                    boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
                  }}
                >
                  <Link to="/guides/how-to-buy-a-house">
                    <Grid
                      container
                      item
                      direction="row"
                      sx={{ padding: "20px" }}
                      gap={3}
                    >
                      <Grid item xs={12}>
                        <img
                          src={steps}
                          alt="steps"
                          style={{ width: "50%", maxHeight: "190px" }}
                        ></img>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h3">
                          <strong>{t("resources:buy-house-7-steps")}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          component="h4"
                          sx={{ color: theme.palette.primary.light }}
                        >
                          <strong>{t("resources:continue")}</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Link>{" "}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ padding: "10px" }}>
                <Paper
                  sx={{
                    minHeight: "318px",
                    boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
                  }}
                >
                  <a href="https://www.biznes.gov.pl/pl/opisy-procedur/-/proc/209">
                    <Grid
                      container
                      item
                      direction="row"
                      sx={{ padding: "20px" }}
                      gap={3}
                    >
                      <Grid item xs={12}>
                        <img
                          src={foreign}
                          alt="foreigner buy"
                          style={{ width: "50%", maxHeight: "190px" }}
                        ></img>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h3">
                          <strong>
                            {t("resources:how-to-buy-real-estate")}
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          component="h4"
                          sx={{ color: theme.palette.primary.light }}
                        >
                          <strong>{t("resources:continue")}</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </a>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ padding: "10px" }}>
                <Paper
                  sx={{
                    minHeight: "318px",
                    boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
                  }}
                >
                  <Link to="/guides/how-to-choose-real-estate-agent">
                    <Grid
                      container
                      item
                      direction="row"
                      sx={{ padding: "20px" }}
                      gap={3}
                    >
                      <Grid item xs={12}>
                        <img
                          src={choose}
                          alt="choose agent"
                          style={{ width: "50%", maxHeight: "190px" }}
                        ></img>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h3">
                          <strong>{t("resources:how-to-choose")}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          component="h4"
                          sx={{ color: theme.palette.primary.light }}
                        >
                          <strong>{t("resources:continue")}</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ padding: "10px" }}>
                <Paper
                  sx={{
                    minHeight: "318px",
                    boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
                  }}
                >
                  <Link to="/guides/real-estate-commision">
                    <Grid
                      container
                      item
                      direction="row"
                      sx={{ padding: "20px" }}
                      gap={3}
                    >
                      <Grid item xs={12}>
                        <img
                          src={costs}
                          alt="agent costs"
                          style={{ width: "50%", maxHeight: "190px" }}
                        ></img>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h3">
                          <strong>{t("resources:about-real-estate")}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          component="h4"
                          sx={{ color: theme.palette.primary.light }}
                        >
                          <strong>{t("resources:continue")}</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Link>
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              xs={12}
            >
              <Grid item xs={12} sm={6} md={4} sx={{ padding: "10px" }}>
                <Paper
                  sx={{
                    minHeight: "318px",
                    boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
                  }}
                >
                  <Link to="/guides/how-to-prepare-my-home">
                    <Grid
                      container
                      item
                      direction="row"
                      sx={{ padding: "20px" }}
                      gap={3}
                    >
                      <Grid item xs={12}>
                        <img
                          src={steps}
                          alt="steps"
                          style={{ width: "50%", maxHeight: "190px" }}
                        ></img>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h3">
                          <strong>
                            {t("resources:how-to-prepare-to-sell")}
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          component="h4"
                          sx={{ color: theme.palette.primary.light }}
                        >
                          <strong>{t("resources:continue")}</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Link>{" "}
                </Paper>
              </Grid>
              <Grid xs={12} sm={6} md={4} sx={{ padding: "10px" }}>
                <Paper
                  sx={{
                    minHeight: "318px",
                    boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
                  }}
                >
                  <a href="https://www.biznes.gov.pl/pl/opisy-procedur/-/proc/209">
                    <Grid
                      container
                      item
                      direction="row"
                      sx={{ padding: "20px" }}
                      gap={3}
                    >
                      <Grid item xs={12}>
                        <img
                          src={foreign}
                          alt="foreigner buy"
                          style={{ width: "50%", maxHeight: "190px" }}
                        ></img>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h3">
                          <strong>
                            {t("resources:sell-estate-in-poland")}
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          component="h4"
                          sx={{ color: theme.palette.primary.light }}
                        >
                          <strong>{t("resources:continue")}</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </a>
                </Paper>
              </Grid>
              <Grid xs={12} sm={6} md={4} sx={{ padding: "10px" }}>
                <Paper
                  sx={{
                    minHeight: "318px",
                    boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
                  }}
                >
                  <Link to="/guides/how-to-choose-real-estate-agent">
                    <Grid
                      container
                      item
                      direction="row"
                      sx={{ padding: "20px" }}
                      gap={3}
                    >
                      <Grid item xs={12}>
                        <img
                          src={choose}
                          alt="choose agent"
                          style={{ width: "50%", maxHeight: "190px" }}
                        ></img>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h3">
                          <strong>{t("resources:how-to-choose")}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          component="h4"
                          sx={{ color: theme.palette.primary.light }}
                        >
                          <strong>{t("resources:continue")}</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ padding: "10px" }}>
                <Paper
                  sx={{
                    minHeight: "318px",
                    boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
                  }}
                >
                  <Link to="/guides/real-estate-commision">
                    <Grid
                      container
                      item
                      direction="row"
                      sx={{ padding: "20px" }}
                      gap={3}
                    >
                      <Grid item xs={12}>
                        <img
                          src={costs}
                          alt="agent costs"
                          style={{ width: "50%", maxHeight: "190px" }}
                        ></img>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h3">
                          <strong>{t("resources:about-real-estate")}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          component="h4"
                          sx={{ color: theme.palette.primary.light }}
                        >
                          <strong>{t("resources:continue")}</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Link>
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default BuyGuide;
