import React, { PropsWithChildren } from "react";
import { Grid, Typography } from "@mui/material";

interface Props {
  heading?: string;
}

const CmsArticle = ({ heading, children }: Props & PropsWithChildren) => {
  return (
    <>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        sx={{
          padding: "0px 60px",
          margin: { xs: "0", md: "0 30%" },
          textAlign: "center",
        }}
        gap={5}
      >
        {heading && (
          <Grid item>
            <Typography variant="h3" component="h2">
              {heading}
            </Typography>
          </Grid>
        )}
        {children}
      </Grid>
    </>
  );
};

export default CmsArticle;
