import {
  Grid,
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import "./commision.scss";
import { useTranslation } from "react-i18next";
import CmsBanner from "components/reusable/cms/CmsBanner";
import commisonbg from "../../../../../images/commisionbg.webp";
import CmsArticle from "components/reusable/cms/CmsArticle";

const Commision = () => {
  const { t } = useTranslation();
  const backgroundImage = `url(${commisonbg})`;
  return (
    <Grid container mb={10} gap={10}>
      <CmsBanner
        heading={t("resources:about-real-estate")}
        imgSrc={backgroundImage}
      />
      <CmsArticle heading={t("resources:how-much-is-commission")}>
        <Grid item>
          <Typography variant="subtitle1">
            {t("resources:if-you-buy-house")}{" "}
            <strong>{t("resources:auction-commission")} </strong>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            {t("resources:heres-how")}{" "}
          </Typography>
        </Grid>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t("resources:sell-price")}</TableCell>
                <TableCell align="right">
                  5% {t("resources:real-estate-commission")}
                </TableCell>
                <TableCell align="right">
                  1%-1,5% {t("resources:realn-commission")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  1.000.000 PLN
                </TableCell>
                <TableCell align="right">50.000 PLN</TableCell>
                <TableCell align="right">
                  10.000PLN {t("resources:to")} 15.000PLN
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  2.000.000 PLN
                </TableCell>
                <TableCell align="right">100.000 PLN</TableCell>
                <TableCell align="right">
                  20.000PLN {t("resources:to")} 30.000PLN
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  3.000.000 PLN
                </TableCell>
                <TableCell align="right">150.000 PLN</TableCell>
                <TableCell align="right">
                  30.000PLN {t("resources:to")} 45.000PLN
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  4.000.000 PLN
                </TableCell>
                <TableCell align="right">200.000 PLN</TableCell>
                <TableCell align="right">
                  40.000PLN {t("resources:to")} 60.000PLN
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CmsArticle>
      <CmsArticle heading={t("resources:can-you-negotiate")}>
        <Typography variant="subtitle1">
          {t("resources:no-regulations")}
        </Typography>
        <Typography variant="subtitle1">
          <strong>{t("resources:fixed-commission")}</strong>
        </Typography>
        <Typography variant="subtitle1">
          {t("resources:please-note")}
        </Typography>
        <Typography variant="subtitle1">
          <strong>{t("resources:best-prices")}</strong>{" "}
          {t("resources:show-you")}
        </Typography>
      </CmsArticle>
      <CmsArticle heading={t("resources:commision-include")}>
        <Typography variant="subtitle1">
          {t("resources:average-commission")}
        </Typography>
      </CmsArticle>
      <CmsArticle heading={t("resources:commission-is-divided")}>
        <Typography variant="subtitle1">
          {t("resources:commission-is-divided")}
        </Typography>
      </CmsArticle>
      <CmsArticle heading={t("resources:commission-included")}>
        <Typography variant="subtitle1">
          {t("resources:technically")}
        </Typography>
        <Typography variant="subtitle1">
          {t("resources:closing-cost")}
        </Typography>
      </CmsArticle>
    </Grid>
  );
};

export default Commision;
