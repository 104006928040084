import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import { useEffect, useState } from "react";
import { getUserOffers } from "components/views/LoginRegister/accountSlice";
import { ResponseOffer } from "components/models/offer";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import OfferTable from "components/views/profile/OfferTable";
import {
  deleteOfferAsync,
  duplicateOfferAsync,
} from "components/views/offers/catalogSlice";
import ProfileContainer from "components/views/profile/components/ProfileContainer";
import ConfirmationDialog from "components/views/utils/ConfirmationDialog";

const Offers = () => {
  const dispatch = useAppDispatch();
  const { userOffers } = useAppSelector((state) => state.account);
  const { t } = useTranslation();
  const isDeleting = useAppSelector(
    (state) => state.catalog.status === "pendingDeleteOffer"
  );
  const isDuplicating = useAppSelector(
    (state) => state.catalog.status === "pendingDuplicateOffer"
  );
  const isLoading = useAppSelector(
    (state) => state.account.status === "pendingFetchUserOffers"
  );
  const [offerForDeletion, setOfferForDeletion] = useState<
    ResponseOffer["id"] | null
  >(null);
  const [offerForDuplication, setOfferForDuplication] = useState<
    ResponseOffer["id"] | null
  >(null);

  useEffect(() => {
    dispatch(getUserOffers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async () => {
    if (!offerForDeletion || isDeleting) return;

    const res = await dispatch(deleteOfferAsync(offerForDeletion));
    setOfferForDeletion(null);
    if (res.type === "catalog/deleteOfferAsync/fulfilled")
      dispatch(getUserOffers());
  };

  const handleDuplicate = async () => {
    if (!offerForDuplication || isDuplicating) return;

    const res = await dispatch(duplicateOfferAsync(offerForDuplication));
    setOfferForDuplication(null);
    if (res.type === "catalog/duplicateOfferAsync/fulfilled")
      dispatch(getUserOffers());
  };

  const handleClickDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: ResponseOffer["id"]
  ) => {
    e.stopPropagation();
    setOfferForDeletion(id);
  };

  const handleClickDuplicate = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: ResponseOffer["id"]
  ) => {
    e.stopPropagation();
    setOfferForDuplication(id);
  };

  return (
    <ProfileContainer>
      <Typography variant="h6" component="h3" sx={{ mb: 3 }}>
        <strong>{t("profile:your-offers")}</strong>
      </Typography>
      <OfferTable
        userOffers={userOffers}
        onDelete={handleClickDelete}
        onDuplicate={handleClickDuplicate}
        isLoading={isLoading}
      />
      <ConfirmationDialog
        open={!!offerForDuplication}
        onConfirm={handleDuplicate}
        onClose={() => setOfferForDuplication(null)}
        text={t("profile:sure-to-duplicate-offer")}
        cancelButtonText={t("profile:cancel")}
        confirmButtonText={t("profile:duplicate")}
        isLoading={isDuplicating}
      />
      <ConfirmationDialog
        open={!!offerForDeletion}
        onConfirm={handleDelete}
        onClose={() => setOfferForDeletion(null)}
        text={t("profile:sure-to-delete-offer")}
        cancelButtonText={t("profile:cancel")}
        confirmButtonText={t("profile:delete")}
        isLoading={isDeleting}
      />
    </ProfileContainer>
  );
};

export default Offers;
