import { IconButton } from "@mui/material";
import { ResponseOffer } from "components/models/offer";
import TooltipComponent from "components/reusable/Tooltip/Tooltip";
import { MdContentCopy, MdDelete, MdOutlineEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import notFoundImage from "images/home-placeholder.webp";
import { formatPrice } from "utils/helpers";
import { useAppSelector } from "components/store/configureStore";
import { TableElement } from "components/models/table";
import ProfileTableLoader from "components/views/profile/components/ProfileTableLoader";
import { Box } from "@mui/system";
import ProfileTable from "components/views/profile/components/ProfileTable";

interface Props {
  userOffers: ResponseOffer[] | null;
  onDelete: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: ResponseOffer["id"]
  ) => void;
  onDuplicate: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: ResponseOffer["id"]
  ) => void;
  isLoading?: boolean;
}

const OfferTable = ({
  userOffers,
  onDelete,
  onDuplicate,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const table = useAppSelector((state) => state.utility.table);

  const offers = userOffers
    ?.slice()
    .sort((a, b) => (a.created_at > b.created_at ? -1 : 1));

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    offer: ResponseOffer
  ) => {
    e.stopPropagation();
    navigate(`/catalog/${offer.id}`);
  };

  const handleRedirect = (
    e: React.MouseEvent<HTMLTableRowElement>,
    id: string
  ) => {
    e.stopPropagation();
    navigate(`/offer/${id}`);
  };

  if (isLoading) return <ProfileTableLoader />;

  if (!offers?.length) return <h1>{t("profile:no-offers")}</h1>;

  return (
    <ProfileTable
      columns={[
        {
          id: "image",
          title: "",
          sx: { py: 0, textAlign: "left" },
        },
        { id: "title", title: t("profile:favorite-title") },
        {
          id: "type",
          title: t("profile:favorite-type"),
          hidden: ["xs"],
        },
        {
          id: "price",
          title: t("profile:search-criteria-price"),
          hidden: ["xs", "sm"],
        },
        {
          id: "actions",
          title: t("profile:favorite-actions"),
        },
      ]}
      rows={offers.map((offer) => ({
        id: offer.id,
        onClick: (e) => handleRedirect(e, offer.id),
        columns: {
          image: (
            <Box
              component="img"
              src={
                offer.featured_image
                  ? offer.featured_image.variants.thumbnail
                  : notFoundImage
              }
              alt={offer.title}
              sx={{
                width: "80px",
                height: "50px",
                objectFit: "cover",
                m: "7px 0px 0px 2px",
                borderRadius: "4px",
              }}
            />
          ),
          title: offer.title,
          type:
            table.OfferType.find(
              (element: TableElement) => element.id === offer.type?.id
            )?.name ?? "-",
          price: offer.price ? formatPrice(offer.price) : "-",
          actions: (
            <>
              <TooltipComponent
                WrappedComponent={
                  <IconButton
                    aria-label="duplicate"
                    onClick={(e) => onDuplicate(e, offer.id)}
                    sx={{ borderRadius: "50%", mr: 1 }}
                  >
                    <MdContentCopy size={24} />
                  </IconButton>
                }
                message={t("profile:duplicate-offer")}
              />
              <TooltipComponent
                WrappedComponent={
                  <IconButton
                    aria-label="edit"
                    onClick={(e) => handleClick(e, offer)}
                    sx={{ borderRadius: "50%", mr: 1 }}
                  >
                    <MdOutlineEdit size={24} />
                  </IconButton>
                }
                message={t("profile:edit-offer")}
              />
              <TooltipComponent
                WrappedComponent={
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => onDelete(e, offer.id)}
                    sx={{ borderRadius: "50%" }}
                  >
                    <MdDelete size={24} />
                  </IconButton>
                }
                message={t("profile:search-criteria-delete")}
              />
            </>
          ),
        },
      }))}
    />
  );
};

export default OfferTable;
