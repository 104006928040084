import { useTranslation } from "react-i18next";
import CmsBanner from "components/reusable/cms/CmsBanner";
import CmsArticle from "components/reusable/cms/CmsArticle";
import CmsList from "components/reusable/cms/CmsList";
import { Grid, Typography } from "@mui/material";

const Statue = () => {
  const { t } = useTranslation();
  const backgroundImage = `url(${"https://cdn.pixabay.com/photo/2018/04/24/14/07/purchase-3347053_1280.jpg"})`;

  return (
    <Grid container gap={10} paddingBottom={20}>
      <CmsBanner
        heading={t("statue:website-regulations")}
        imgSrc={backgroundImage}
      />
      <CmsArticle heading={t("statue:general-concepts")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("statue:statue")}</strong> {t("statue:statue-def")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("statue:service")}</strong> {t("statue:service-def-1")}{" "}
              &quot;RealN.pl&quot;
              {t("statue:service-def-2")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("statue:service-provider")}</strong>{" "}
              {t("statue:service-provider-def-1")} &quot;RealN Sp.zo.o&quot;{" "}
              {t("statue:service-provider-def-2")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("statue:service-recipient")}</strong>{" "}
              {t("statue:service-recipient-def")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              <strong>{t("statue:electronic-communication")}</strong>{" "}
              {t("statue:electronic-communication-def")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("statue:general-provisions")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("statue:regulations-define")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:the-subject-of-the-service")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:all-possible-content")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:service-recipent")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:service-provider-2")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:service-provider-3")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("statue:terms-of-use")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("statue:use-website")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:service-recipients-obliged")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:service-recipents-may-not")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:technical-requirements")}
            </Typography>
            <CmsList>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:display-of-website")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:internet-connection")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:any-web-browser")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:js-scripts")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:cookies")}
                </Typography>
              </li>
            </CmsList>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:safety-service-provider")}{" "}
              <a href="https://nety.pl/cyberbezpieczenstwo/zasady-ogolne-korzystania-z-sieci-internet/">
                {t("statue:online-safety-rules")}
              </a>
              ,
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:activities-performed-personally-header")}
            </Typography>
            <CmsList>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:activities-performed-personally-1")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:activities-performed-personally-2")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:activities-performed-personally-3")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:activities-performed-personally-4")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:activities-performed-personally-5")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:activities-performed-personally-6")}
                </Typography>
              </li>
            </CmsList>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:cybersecurity-incident")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("statue:registration-terms")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("statue:registration-terms-1")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:registration-terms-2")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:registration-terms-3")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:registration-terms-4")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:registration-terms-5")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:registration-terms-6")}
            </Typography>
            <CmsList>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:registration-terms-6-1")}
                </Typography>
              </li>
            </CmsList>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:registration-terms-7")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:registration-terms-8")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:registration-terms-9")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:registration-terms-10")}
            </Typography>
            <CmsList>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:registration-terms-10-1")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:registration-terms-10-2")}
                </Typography>
              </li>
            </CmsList>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:registration-terms-11")}
            </Typography>
            <CmsList>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:registration-terms-11-1")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:registration-terms-11-2")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:registration-terms-11-2")}
                </Typography>
              </li>
            </CmsList>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("statue:terms-of-provision")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("statue:terms-of-provision-1")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:terms-of-provision-2")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:terms-of-provision-3")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:terms-of-provision-4")}
            </Typography>
            <CmsList>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-provision-4-1")}
                </Typography>
              </li>
            </CmsList>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:terms-of-provision-5")}
            </Typography>
            <CmsList>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-provision-5-1")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-provision-5-2")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-provision-5-3")}
                </Typography>
              </li>
            </CmsList>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:terms-of-provision-6")}
            </Typography>
            <CmsList>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-provision-6-1")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-provision-6-2")}
                </Typography>
              </li>
            </CmsList>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:terms-of-provision-7")}
            </Typography>
            <CmsList>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-provision-7-1")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-provision-7-2")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-provision-7-3")}
                </Typography>
              </li>
            </CmsList>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("statue:terms-of-communication")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("statue:terms-of-communication-1")}
            </Typography>
            <CmsList>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-communication-1-1")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-communication-1-2")}
                </Typography>
              </li>
            </CmsList>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:terms-of-communication-2")}
            </Typography>
            <CmsList>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-communication-2-1")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-communication-2-2")}
                </Typography>
              </li>
            </CmsList>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:terms-of-communication-3")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:terms-of-communication-4")}
            </Typography>
            <CmsList>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-communication-4-1")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-communication-4-2")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-communication-4-3")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-communication-4-4")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:terms-of-communication-4-5")}
                </Typography>
              </li>
            </CmsList>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("statue:collection-about-service")}>
        <Typography variant="subtitle1">
          {t("statue:collection-about-service-p-1")}
        </Typography>
        <Typography variant="subtitle1">
          {t("statue:collection-about-service-p-2")}
        </Typography>
        <Typography variant="subtitle1">
          {t("statue:collection-about-service-p-3-1")} &#8222;
          <a href="https://realn.pl/rodo">
            {t("statue:collection-about-service-p-3-2")}
          </a>
          &#8221; {t("statue:collection-about-service-p-3-3")} &#8222;
          <a href="https://realn.pl/polityka-prywatnosci/">
            {t("statue:collection-about-service-p-3-4")}
          </a>
          &#8222;{t("statue:collection-about-service-p-3-5")}
        </Typography>
        <CmsList>
          <li>
            <em>{t("statue:data-collected-automatically")}</em>
            <br />
            {t("statue:efficient-operation")}
            <CmsList>
              <li>
                <Typography variant="subtitle1">{t("privacy:ip")}</Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("privacy:browser-type")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("privacy:screen-resolution")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("privacy:location")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("privacy:opened-website")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("privacy:time-spent")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("privacy:system")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("privacy:last-page")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("privacy:current-page")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("privacy:language")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("privacy:connection-speed")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("privacy:internet-provider")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:anonymous-demographic-data")}
                </Typography>
                <CmsList>
                  <li>
                    <Typography variant="subtitle1">
                      {t("statue:sex")}
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle1">
                      {t("statue:age")}
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle1">
                      {t("statue:interests")}
                    </Typography>
                  </li>
                </CmsList>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:annoymous-data")}
                </Typography>
                <CmsList>
                  <li>
                    <Typography variant="subtitle1">
                      {t("statue:data-related-remarketing")}
                    </Typography>
                  </li>
                </CmsList>
              </li>
            </CmsList>
            <Typography variant="subtitle1">
              {t("statue:google-analytics")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:data-collected-during-registration")}
              <br />
              {t("statue:data-collector")}
            </Typography>
            <Typography variant="subtitle1">
              {t("statue:logged-in-service")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:subscribing-newsletter")}
              <br />
              {t("statue:email")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("statue:copyright")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("statue:copyright-1")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:copyright-2")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:copyright-3")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("statue:changes-requlations")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("statue:changes-requlations-1")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:changes-requlations-2")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:changes-requlations-3")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:changes-requlations-4")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:changes-requlations-5")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
      <CmsArticle heading={t("statue:final-provisions")}>
        <CmsList>
          <li>
            <Typography variant="subtitle1">
              {t("statue:final-provisions-1")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:final-provisions-2")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:final-provisions-3")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:final-provisions-4")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:final-provisions-5")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:final-provisions-6")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:final-provisions-7")}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              {t("statue:final-provisions-8")}
            </Typography>
            <CmsList>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:final-provisions-8-1")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:final-provisions-8-2")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  {t("statue:final-provisions-8-3")}
                </Typography>
              </li>
            </CmsList>
            <Typography variant="subtitle1">
              {t("statue:final-provisions-8-4")}
            </Typography>
          </li>
        </CmsList>
      </CmsArticle>
    </Grid>
  );
};

export default Statue;
