import { useTranslation } from "react-i18next";
import SmallContact from "../../utils/SmallContact";
import CmsBanner from "components/reusable/cms/CmsBanner";
import housebg from "../../../../images/back4.webp";
import CmsArticle from "components/reusable/cms/CmsArticle";
import { Grid, Typography } from "@mui/material";
import CmsSubArticle from "components/reusable/cms/CmsSubArticle";
const TenAdvices = () => {
  const { t } = useTranslation();

  return (
    <Grid container gap={10} paddingBottom={20}>
      <CmsBanner
        heading={t("sell:10-amazing-tips")}
        imgSrc={`url(${housebg})`}
      />
      <CmsArticle heading={t("sell:10-amazing-tips-header")}>
        <Grid item>
          <CmsSubArticle heading={t("sell:prepare-house-to-sell")}>
            <Typography variant="subtitle1">{t("sell:keep-clean")}</Typography>
          </CmsSubArticle>
        </Grid>
        <Grid item>
          <CmsSubArticle heading={t("sell:first-impression")}>
            <Typography variant="subtitle1">
              {t("sell:improving-facade")}
            </Typography>
          </CmsSubArticle>
        </Grid>
        <Grid item>
          <CmsSubArticle heading={t("sell:refreshing")}>
            <Typography variant="subtitle1">
              {t("sell:painting-walls")}
            </Typography>
          </CmsSubArticle>
        </Grid>
        <Grid item>
          <CmsSubArticle heading={t("sell:proffesional-photos")}>
            <Typography variant="subtitle1">
              {t("sell:use-the-services")}
            </Typography>
          </CmsSubArticle>
        </Grid>
        <Grid item>
          <CmsSubArticle heading={t("sell:online-marketing")}>
            <Typography variant="subtitle1">
              {t("sell:use-online-marketing")}
            </Typography>
          </CmsSubArticle>
        </Grid>
        <Grid item>
          <CmsSubArticle heading={t("sell:highlight-strengths")}>
            <Typography variant="subtitle1">
              {t("sell:highlight-unique-features")}
            </Typography>
          </CmsSubArticle>
        </Grid>
        <Grid item>
          <CmsSubArticle heading={t("sell:competitive-price")}>
            <Typography variant="subtitle1">
              {t("sell:conduct-market-research")}
            </Typography>
          </CmsSubArticle>
        </Grid>
        <Grid item>
          <CmsSubArticle heading={t("sell:open-session")}>
            <Typography variant="subtitle1">{t("sell:open-house")}</Typography>
          </CmsSubArticle>
        </Grid>
        <Grid item>
          <CmsSubArticle heading={t("sell:work-with-agent")}>
            <Typography variant="subtitle1">
              {t("sell:hire-an-agent")}
            </Typography>
          </CmsSubArticle>
        </Grid>
        <Grid item>
          <CmsSubArticle heading={t("sell:be-flexible")}>
            <Typography variant="subtitle1">
              {t("sell:negotiation-open")}
            </Typography>
          </CmsSubArticle>
        </Grid>
        <CmsSubArticle heading={t("sell:remember")} />
      </CmsArticle>
      <SmallContact />{" "}
    </Grid>
  );
};

export default TenAdvices;
