import { useAppSelector } from "components/store/configureStore";
import { useTranslation } from "react-i18next";
import ProfileContainer from "components/views/profile/components/ProfileContainer";
import { Typography } from "@mui/material";

const Tokens = () => {
  const { t } = useTranslation();
  const { tokenList } = useAppSelector((state) => state.account);

  return (
    <ProfileContainer>
      <Typography variant="h6" component="h3" sx={{ mb: 3 }}>
        <strong>{t("profile:tokens")}</strong>
      </Typography>
      {/* <button onClick={handleListTokens}> {t("profile:list")} </button> */}
      <ul>
        {tokenList
          ?.filter((token) => token.used_at == null)
          .map((token: any) => (
            <li key={token.id}>
              {token.token}
              <button
                onClick={() => navigator.clipboard.writeText(token.token)}
              >
                {t("profile:copy")}
              </button>
            </li>
          ))}
      </ul>

      {/* <div className="new-token-box">
          <button onClick={handleCreateToken}> {t("profile:create")} </button>
          {newToken && (
            <div>
              <p> {t("profile:new")} {newToken}</p>
              <button onClick={() => navigator.clipboard.writeText(newToken)}>
              {t("profile:copy")} 
              </button>
            </div>
          )}
        </div> */}
    </ProfileContainer>
  );
};

export default Tokens;
