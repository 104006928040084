import { Button, CircularProgress, Grid } from "@mui/material";
import { ResponseOffer } from "components/models/offer";
import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import { showNotification } from "components/store/utilitySlice";
import { deleteOfferAsync } from "components/views/offers/catalogSlice";
import ConfirmationDialog from "components/views/utils/ConfirmationDialog";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface DeleteOfferButtonProps {
  offer: ResponseOffer;
}

const DeleteOfferButton = ({ offer }: DeleteOfferButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const userId = useAppSelector((state) => state.account.user?.id);
  const isLoading = useAppSelector(
    (state) => state.catalog.status === "pendingDeleteOffer"
  );

  const handleDeleteOffer = () => {
    if (!offer) return;
    setIsDialogOpen(false);
    dispatch(deleteOfferAsync(offer?.id)).then((response) => {
      if (response.payload.success === true) {
        dispatch(
          showNotification({
            message: t("buy:offer-deleted"),
            severity: "success",
          })
        );
      }
      navigate("/profile/offers");
    });
  };

  if (!offer?.user || offer.user.id !== userId) return null;

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="error"
            size="large"
            fullWidth
            onClick={() => setIsDialogOpen(true)}
            startIcon={
              isLoading ? <CircularProgress size={20} color="inherit" /> : null
            }
            disabled={isLoading}
          >
            {t("buy:delete-offer")}
          </Button>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={isDialogOpen}
        text={t("utils:you-shure-to-delete")}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={handleDeleteOffer}
        cancelButtonText={t("utils:no")}
        confirmButtonText={t("utils:yes")}
        isLoading={isLoading}
      />
    </>
  );
};

export default DeleteOfferButton;
