import variables from "../../../variables.js";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import CmsBanner from "components/reusable/cms/CmsBanner";
import background from "../../../images/officebg.webp";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <Grid container gap={10} paddingBottom={20}>
      <CmsBanner
        heading={t("main:contact-us")}
        text={t("main:we-are-disposal")}
        imgSrc={`url(${background})`}
      />
      <Grid container item xs={12} mt={10} justifyContent="center">
        <Grid
          item
          xs={8}
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          sx={{ textAlign: "center" }}
        >
          <Grid item container xs={12} sm={4} mb={10} gap={10}>
            <Grid item xs={12}>
              <i className="fa-solid fa-phone" style={{ fontSize: "30px" }}></i>
            </Grid>
            <Grid item xs={12}>
              {" "}
              <Typography variant="h4" component="h2">
                <a
                  href={`tel: ${variables.realnPhone}`}
                  style={{ color: "#4396ca" }}
                >
                  {variables.realnPhone}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {" "}
              <Typography variant="subtitle1">{t("main:call-us")}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={4} mb={10} gap={10}>
            <Grid item xs={12}>
              {" "}
              <i
                className="fa-regular fa-message"
                style={{ fontSize: "30px" }}
              ></i>
            </Grid>
            <Grid item xs={12}>
              <a href={`mailto: ${variables.realnEmail}`}>
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{ color: "#4396ca" }}
                >
                  {t("main:write-to-us")}
                </Typography>
              </a>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("main:customer-service")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={4} mb={10} gap={10}>
            <Grid item xs={12}>
              <i
                className="fa-solid fa-share-nodes"
                style={{ fontSize: "30px" }}
              ></i>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" component="h2" sx={{ color: "#4396ca" }}>
                {t("main:find-us")}
              </Typography>
            </Grid>
            <Grid item container xs={12} justifyContent="center">
              <Grid item container xs={7} justifyContent="space-between">
                <Grid item>
                  <a href={variables.realnFacebook}>
                    <i
                      className="fa-brands fa-facebook"
                      style={{ fontSize: "30px", color: "black" }}
                    ></i>
                  </a>
                </Grid>
                <Grid item>
                  <a href={variables.realnInstagram}>
                    <i
                      className="fa-brands fa-instagram"
                      style={{ fontSize: "30px", color: "black" }}
                    ></i>
                  </a>
                </Grid>
                <Grid item>
                  <a href={variables.realnLinkedin}>
                    <i
                      className="fa-brands fa-linkedin"
                      style={{ fontSize: "30px", color: "black" }}
                    ></i>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contact;
