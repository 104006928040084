import {
  FormHelperText,
  Grid,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FieldErrors } from "react-hook-form";
import { UserPreferencesFormValues } from "components/models/user";
import { useTranslation } from "react-i18next";

interface BudgetSliderProps {
  value: number[];
  onChange: (value: number[]) => void;
  errors?: FieldErrors<
    Pick<UserPreferencesFormValues, "min_budget" | "max_budget">
  >;
}

const BudgetSlider = ({
  value,
  onChange,
  errors,
}: Readonly<BudgetSliderProps>) => {
  const { t } = useTranslation();
  const hasErrors = Boolean(errors && Boolean(Object.keys(errors).length));

  return (
    <Stack>
      <Typography variant="caption">{t("profile:budget")}</Typography>
      <Slider
        getAriaLabel={() => "Budget range"}
        value={value}
        min={0}
        step={10000}
        max={10000000}
        onChange={(_e, value) => onChange(value as number[])}
        sx={{ marginBottom: "8px", width: "90%", margin: "5px 5%" }}
        {...(hasErrors && { color: "error" })}
      />
      <Grid container sx={{ justifyContent: "space-between" }}>
        <Grid item xs={4} sm={2} md={1.5}>
          <TextField
            variant="standard"
            type="number"
            value={value[0]}
            inputProps={{ step: 10000, sx: { textAlign: "center" } }}
            onChange={(event) =>
              onChange([parseInt(event.target.value), value[1]])
            }
          />
        </Grid>
        <Grid item xs={4} sm={2} md={1.5}>
          <TextField
            variant="standard"
            type="number"
            value={value[1]}
            inputProps={{ step: 10000, sx: { textAlign: "center" } }}
            onChange={(event) =>
              onChange([value[0], parseInt(event.target.value)])
            }
          />
        </Grid>
      </Grid>
      {hasErrors && (
        <Stack alignItems="center">
          {Object.entries(errors ?? {}).map(([key, value]) => (
            <FormHelperText error key={key}>
              {value?.message}
            </FormHelperText>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default BudgetSlider;
