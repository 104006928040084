import { Skeleton, Stack } from "@mui/material";

const ProfileTableLoader = () => {
  return (
    <Stack spacing={2}>
      {Array(5)
        .fill(0)
        .map((_, i) => (
          <Skeleton key={i} variant="rounded" width="100%" height={56} />
        ))}
    </Stack>
  );
};

export default ProfileTableLoader;
