import "./main.scss";
import EstateSlider from "../utils/EstateSlider";
import pic2 from "../../../images/back6.webp";
import pic5 from "../../../images/back3.webp";
import SmallContact from "../utils/SmallContact";
import Searcher from "../utils/Searcher/Searcher";
import { useAppDispatch } from "components/store/configureStore";
import { useEffect } from "react";
import { searchOffersAsync } from "../offers/catalogSlice";

import { useTranslation } from "react-i18next";
import SearchWithState from "../utils/Searcher/SearchWithState";
import { Link as RouterLink } from "react-router-dom";
import { Divider, Grid, Link, Stack, Typography } from "@mui/material";
import headerBackgroundImage from "images/back1.webp";

const Main = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(searchOffersAsync(""));
  }, []);

  return (
    <>
      <Grid
        container
        sx={{
          backgroundImage: `linear-gradient(
            176deg,
            rgba(52, 52, 52, 1) 0%,
            rgba(0, 0, 0, 0) 67%
          ),
          url("${headerBackgroundImage}")`,
          height: { xs: 500, md: 450 },
          backgroundSize: "cover",
          backgroundPosition: "center",
          alignItems: "center",
        }}
        component="header"
      >
        <Grid
          item
          xs={11}
          sm={9}
          sx={{
            mt: { xs: 25, md: 10 },
            mx: "auto",
          }}
        >
          <Stack spacing={{ xs: 4, md: 6 }}>
            <Typography
              component="h1"
              sx={{
                fontSize: { xs: "20px", sm: "24px", md: "32px" },
                color: "common.white",
                letterSpacing: "0.8px",
                lineHeight: 1.2,
              }}
            >
              <strong>{t("main:technology-driven")}</strong>
            </Typography>
            <Searcher />
          </Stack>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="flex-start"
          gap={{ xs: 0, sm: 10 }}
          sx={{ margin: { xs: "150px 0 0 0", sm: "100px 0" } }}
        >
          <Grid item xs={11} sm={3}>
            <img
              src={pic5}
              style={{
                width: "100%",
                boxShadow: "-1px 3px 18px -7px #313131",
              }}
              alt="home for sell"
            ></img>
          </Grid>
          <Grid
            item
            container
            alignItems="flex-start"
            xs={11}
            sm={4}
            mt={5}
            gap={3}
          >
            <Typography variant="h1">
              <strong>
                {t("main:sell-apartments")} <br />
                {t("main:quickly-and-conveniently")}
              </strong>
            </Typography>
            <Typography variant="subtitle1">
              {t("main:agents-help-you")}
              <br /> {t("main:start-from")}
            </Typography>
            <Grid item container>
              {/* <input type="text" placeholder={t("main:enter-email")}></input>
              <p>{t("main:next")}</p> */}
              {/* <a className="main-top-searcher-content">
                {t("main:contact")}
              </a> */}
              <Link component={RouterLink} to="/contact" variant="button">
                {t("main:contact")}
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="flex-start"
          gap={10}
          sx={{ margin: { xs: "50px 0", sm: "50px  0 100px 0" } }}
        >
          <Grid container gap={3} item xs={11} sm={4}>
            <Typography variant="h1">
              <strong>
                {t("main:dream-apartment")} <br /> {t("main:awaits")}
              </strong>
            </Typography>
            <Typography variant="subtitle1">
              {t("main:find-own-corner")}
            </Typography>
            <SearchWithState />
          </Grid>
          <Grid item xs={11} sm={3}>
            <img
              style={{
                width: "100%",
                boxShadow: "-1px 3px 18px -7px #313131",
              }}
              src={pic2}
              alt="home for rent"
            ></img>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid item xs={11} sm={8}>
          <Typography variant="h1">
            <strong>{t("main:check-apartments")}:</strong>
          </Typography>
          <Divider
            sx={{
              borderColor: "#000000",
              borderWidth: "1px",
              marginTop: "30px",
            }}
          />
        </Grid>
      </Grid>
      <EstateSlider />
      <SmallContact />
    </>
  );
};

export default Main;
