import SmallContact from "../../utils/SmallContact";
import homebg from "../../../../images/icons/homebg.png";
import homebg2 from "../../../../images/icons/landbg.png";
import homebg3 from "../../../../images/icons/steps.png";
import homebg4 from "../../../../images/icons/guide.png";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SearchWithState from "components/views/utils/Searcher/SearchWithState";
import { Grid, Paper, Typography } from "@mui/material";

const SellWorth = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent={"center"}>
      <Grid
        item
        container
        alignItems={"center"}
        gap={3}
        sx={{
          marginTop: "60px",
          width: "100%",
          height: "400px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          justify: "center",
          padding: { sm: "0", xs: "20px" },
        }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            width: "100%",
            padding: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            textAlign: "left",
          }}
        >
          <Typography variant="h1" sx={{ padding: "20px 0" }}>
            {t("sell:how-much-is-my-house")}
          </Typography>
          <Typography variant="subtitle1" mb={2}>
            {t("sell:view-nearby-sales")}
          </Typography>
          <Grid item xs={10}>
            <SearchWithState />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <img
            style={{ maxHeight: "300px", height: "auto", width: "100%" }}
            src={homebg}
            alt="background"
          ></img>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent={"center"}
        xs={12}
        sx={{
          backgroundColor: "rgb(238, 238, 238)",
          width: "100%",
          padding: "30px 0",
        }}
      >
        <Grid
          item
          container
          direction="row"
          sm={8}
          xs={12}
          justifyContent={"center"}
          sx={{
            backgroundColor: "rgb(238, 238, 238)",
            width: "100%",
            padding: "30px 10px",
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h4" component="h2" mb={2}>
              <strong>{t("sell:accurate-valuation")}</strong>
            </Typography>
            <Typography variant="subtitle1">
              {t("sell:to-count-realn")}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            xs={12}
            mt={10}
          >
            <Grid item xs={12} sm={4}>
              <Typography variant="h5" component="h4" mb={2}>
                {t("sell:track-home-value")}
              </Typography>
              <Typography variant="subtitle1">
                {t("sell:know-value-instantly")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ padding: { xs: "20px 0", sm: "0 10px" } }}
            >
              <Typography variant="h5" component="h4" mb={2}>
                {t("sell:see-local-trends")}
              </Typography>
              <Typography variant="subtitle1">
                {t("sell:stay-up-to-date")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h5" component="h4" mb={2}>
                {t("sell:get-a-free-report")}
              </Typography>
              <Typography variant="subtitle1">
                {t("sell:receive-monthly-email-updates")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} xs={12} mt={10} gap={10}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h1">{t("sell:more-resources")}</Typography>
        </Grid>
        <Grid item container sm={8} xs={12}>
          <Grid item container xs={12} sm={4}>
            <Paper
              sx={{
                padding: "10px 10px 100px 10px",
                width: "100%",
                boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
              }}
            >
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <img
                  src={homebg4}
                  alt="home"
                  style={{ maxHeight: "200px", height: "auto" }}
                ></img>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h5" component="h4" mb={2}>
                  {t("sell:how-calculate")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" component="h5">
                  {" "}
                  <Link
                    to="/sell/how-much-is-it-worth/find-the-value"
                    style={{ color: "#4396ca" }}
                  >
                    {t("sell:continue")}
                  </Link>
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <Grid item container xs={12} sm={4}>
            <Paper
              sx={{
                width: "100%",
                padding: "10px",
                margin: { xs: "0", sm: "0 30px" },
                boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
              }}
            >
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <img
                  src={homebg2}
                  alt="home"
                  style={{ maxHeight: "200px", height: "auto" }}
                ></img>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h5" component="h4" mb={2}>
                  {t("sell:five-ways")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" component="h5">
                  <Link
                    to="/sell/how-much-is-it-worth/five-ways"
                    style={{ color: "#4396ca" }}
                  >
                    {t("sell:continue")}
                  </Link>
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <Grid item container xs={12} sm={4}>
            <Paper
              sx={{
                padding: "10px",
                boxShadow: "0 0 20px -2px rgba(66,68,90,.3)",
              }}
            >
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <img
                  src={homebg3}
                  alt="home"
                  style={{ maxHeight: "200px", height: "auto" }}
                ></img>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h5" component="h4" mb={2}>
                  {t("sell:10-amazing-tips")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" component="h5">
                  <Link
                    to="/sell/how-much-is-it-worth/ten-advices"
                    style={{ color: "#4396ca" }}
                  >
                    {" "}
                    {t("sell:continue")}
                  </Link>
                </Typography>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <SmallContact />
    </Grid>
  );
};

export default SellWorth;
