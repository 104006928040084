import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import CmsBanner from "components/reusable/cms/CmsBanner";
import CmsArticle from "components/reusable/cms/CmsArticle";
import CmsSubArticle from "components/reusable/cms/CmsSubArticle";

export default function About() {
  const { t } = useTranslation();
  const backgroundImage = `url(${"https://cdn.pixabay.com/photo/2018/04/24/14/07/purchase-3347053_1280.jpg"})`;

  return (
    <Grid container gap={10} paddingBottom={20}>
      <CmsBanner heading={t("about:who-we-are")} imgSrc={backgroundImage} />
      <CmsArticle heading={t("about:the-best-agents")}>
        <Grid item container gap={3}>
          <Grid item>
            <Typography variant="subtitle1">
              {t("about:we-connect-local")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              <strong>{t("about:realn-agents")}</strong>{" "}
              {t("about:are-among-the")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              <strong>{t("about:our-technology-team")}</strong>{" "}
              {t("about:realn-is-a-group")}
            </Typography>
          </Grid>
        </Grid>
        <CmsSubArticle heading={t("about:sell-your-house")}>
          <Grid item>
            <Typography variant="subtitle1">
              {t("about:indepentent-research")}
            </Typography>
          </Grid>
        </CmsSubArticle>
        <CmsSubArticle heading={t("about:save-thousands")}>
          <Grid item>
            <Typography variant="subtitle1">
              {t("about:when-you-buy-and-sell")}
            </Typography>
          </Grid>
        </CmsSubArticle>
        <CmsSubArticle heading={t("about:we-invite")}>
          <Grid item>
            <Typography variant="subtitle1">
              {t("about:schedule-an-appointment")}
            </Typography>
          </Grid>
        </CmsSubArticle>
      </CmsArticle>

      <CmsArticle heading={t("about:realn-technology")}>
        <Grid item container gap={3}>
          <Grid item>
            <Typography variant="subtitle1">
              {t("about:our-realn-technology-team")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {" "}
              {t("about:our-mission")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              <strong>{t("about:experience-and-knowledge")}</strong>{" "}
              {t("about:our-experts-have-many")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              <strong>{t("about:innovation")}</strong>{" "}
              {t("about:our-team-is-constantly")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              <strong>{t("about:advanced-ai-tools")}</strong>{" "}
              {t("about:we-operate-at-the-border")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              <strong>{t("about:agent-support")}</strong>{" "}
              {t("about:technological-solutions")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              <strong>{t("about:security-and-privacy")}</strong>{" "}
              {t("about:technology-team-pays")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {t("about:we-can-provide-our-clients")}
            </Typography>
          </Grid>
        </Grid>
      </CmsArticle>

      <CmsArticle heading={t("about:partners-on-the-path-to-success")}>
        <Grid item container gap={3}>
          <Typography variant="subtitle1">
            {t("about:not-only-real-estate-professionals")}
          </Typography>
          <Typography variant="subtitle1">
            <strong>{t("about:experience-and-wisdom")}</strong>{" "}
            {t("about:agents-are-experienced")}
          </Typography>
          <Typography variant="subtitle1">
            <strong>{t("about:local-knowledge")}</strong>{" "}
            {t("about:agents-are-familiar")}
          </Typography>
          <Typography variant="subtitle1">
            <strong>{t("about:support-and-advice")}</strong>{" "}
            {t("about:find-the-righ-property")}
          </Typography>
          <Typography variant="subtitle1">
            <strong>{t("about:personalized-service")}</strong>{" "}
            {t("about:each-client-is-unique")}
          </Typography>
          <Typography variant="subtitle1">
            <strong>{t("about:integrated-technologies")}</strong>{" "}
            {t("about:access-to-advanced-tools")}
          </Typography>
          <Typography variant="subtitle1">
            <strong>{t("about:taking-care-of-interest")}</strong>{" "}
            {t("about:agents-work-for-you")}
          </Typography>
          <Typography variant="subtitle1">
            <strong>{t("about:proven-successes")}</strong>{" "}
            {t("about:many-successful-transactions")}
          </Typography>

          <Typography variant="subtitle1">
            {t("about:real-estate-transaction")}
          </Typography>
        </Grid>
      </CmsArticle>
    </Grid>
  );
}
