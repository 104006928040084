import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Typography } from "@mui/material";
import CmsBanner from "components/reusable/cms/CmsBanner";
import background from "../../../../images/advices.webp";
import CmsArticle from "components/reusable/cms/CmsArticle";
import CmsSubArticle from "components/reusable/cms/CmsSubArticle";

const TipsForRenters = () => {
  const { t } = useTranslation();

  return (
    <Grid container gap={10} paddingBottom={20}>
      <CmsBanner heading={t("rent:advices")} imgSrc={`url(${background})`} />
      <CmsArticle>
        <Grid item>
          <Typography variant="subtitle1">{t("rent:regardless")}</Typography>
        </Grid>
        <CmsSubArticle heading={t("rent:choose-right-place")}>
          <Grid item>
            <Typography variant="subtitle1">
              {t("rent:choose-right-place-text")}
            </Typography>
          </Grid>
        </CmsSubArticle>
        <CmsSubArticle heading={t("rent:set-a-budget")}>
          <Grid item>
            <Typography variant="subtitle1">
              {t("rent:determine-budget")}
            </Typography>
          </Grid>
        </CmsSubArticle>
        <CmsSubArticle heading={t("rent:rental-agreement")}>
          <Grid item>
            <Typography variant="subtitle1">
              {t("rent:rental-agreement-text")}
            </Typography>
          </Grid>
        </CmsSubArticle>
        <CmsSubArticle heading={t("rent:document")}>
          <Grid item>
            <Typography variant="subtitle1">
              {t("rent:document-text")}
            </Typography>
          </Grid>
        </CmsSubArticle>
        <CmsSubArticle heading={t("rent:payments-deadlines")}>
          <Grid item>
            <Typography variant="subtitle1">
              {t("rent:payments-deadlines-text")}
            </Typography>
          </Grid>
        </CmsSubArticle>
        <CmsSubArticle heading={t("rent:report-breakdowns")}>
          <Grid item>
            <Typography variant="subtitle1">
              {t("rent:report-breakdowns-text")}
            </Typography>
          </Grid>
        </CmsSubArticle>
        <CmsSubArticle heading={t("rent:stay-organized")}>
          <Grid item>
            <Typography variant="subtitle1">
              {t("rent:stay-organized-text")}
            </Typography>
          </Grid>
        </CmsSubArticle>
        <CmsSubArticle heading={t("rent:dispute-resolution")}>
          <Grid item>
            <Typography variant="subtitle1">
              {t("rent:dispute-resolution-text")}
            </Typography>
          </Grid>
        </CmsSubArticle>
      </CmsArticle>
      <CmsArticle>
        <Grid item>
          <Typography variant="subtitle1">
            <strong>{t("rent:remember")}</strong>
          </Typography>
        </Grid>
      </CmsArticle>
      <Grid
        container
        xs={12}
        style={{ backgroundColor: "#f4f6f7", padding: "30px" }}
        justifyContent="center"
        gap={10}
        mt={3}
      >
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h3">{t("rent:check-apartments")}</Typography>
        </Grid>
        <Grid item container xs={6} sm={4} sx={{ justifyContent: "flex-end" }}>
          <Paper sx={{ padding: "30px 10px", maxWidth: "280px" }}>
            <Link
              to="/guides/how-to-rent-a-house"
              style={{ textDecoration: "none", color: "#4f4f4f" }}
            >
              <Grid item mb={5}>
                <Typography variant="h4">{t("rent:rent")}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" style={{ color: "#4396ca" }}>
                  {t("rent:find-out-more")}
                </Typography>
              </Grid>
            </Link>
          </Paper>
        </Grid>
        <Grid item container xs={6} sm={4}>
          <Paper sx={{ padding: "30px 10px", maxWidth: "280px" }}>
            <Link
              to="/guides/buy-or-rent"
              style={{ textDecoration: "none", color: "#4f4f4f" }}
            >
              <Grid item mb={5}>
                <Typography variant="h4">
                  {t("rent:buying-or-renting")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" style={{ color: "#4396ca" }}>
                  {t("rent:find-out-more")}
                </Typography>
              </Grid>
            </Link>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TipsForRenters;
