import React, { PropsWithChildren } from "react";
import { Typography, Grid } from "@mui/material";

interface Props {
  heading?: string;
}
const CmsList = ({ heading, children }: Props & PropsWithChildren) => {
  return (
    <Grid item style={{ textAlign: "left", width: "100%" }}>
      <Typography variant="subtitle1">
        <strong>{heading}</strong>
      </Typography>
      <ul>{children}</ul>
    </Grid>
  );
};

export default CmsList;
